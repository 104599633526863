import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-select";
import { addNewJob, jobCompanyList } from "../../api/job";
import { getAllSkills, getAllCities, uploadImage } from "../../api/common";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { job_types } from "../../common/job_types";
const initialState = {
  company_id: "",
  job_title: "",
  min_experience: "",
  max_experience: "",
  locations: "",
  skills: [],
  job_position: "",
  job_ctc: "",
  job_details: "",
  job_sector: "0",
  job_types: "",
  published: "0",
  job_publish_schedule: new Date(),
  application_deadline: new Date(),
  add_to_hot_jobs: "0",
  show_popup_how_did_you_know: false,
  profile_image: "",
};

const NewJobs = () => {
  const [data, setData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [skills, setSkills] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const skillsData = await getAllSkills();
        setSkills(skillsData);
        const locationsData = await getAllCities();
        setLocations(locationsData);
        const cmp = await jobCompanyList();
        setCompanies(cmp);
      } catch (error) {
        swal("Error", "Failed to fetch data", "error");
      }
    };
    fetchData();
  }, []);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
      const response = await uploadImage(formData);
      if (response !== false) {
        setData({ ...data, profile_image: response.data });
        swal("Success", "Image uploaded successfully", "success");
      } else {
        swal("Error", "Image upload failed", "error");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSkillsChange = (selectedOptions) => {
    setData((prevState) => ({
      ...prevState,
      skills: selectedOptions.map((option) => option.value),
    }));
  };

  const handleLocationsChange = (selectedOptions) => {
    setData((prevState) => ({
      ...prevState,
      locations: selectedOptions.map((option) => option.value),
    }));
  };

  const handleJobTypesChange = (selectedOptions) => {
    setData((prevState) => ({
      ...prevState,
      job_types: selectedOptions.map((option) => option.value),
    }));
  };

  const handleDateChange = (date, name) => {
    setData((prevState) => ({
      ...prevState,
      [name]: date,
    }));
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.company_id = data.company_id ? "" : "This field is required.";
    tempErrors.job_title = data.job_title ? "" : "This field is required.";
    tempErrors.min_experience = data.min_experience
      ? ""
      : "This field is required.";
    tempErrors.max_experience = data.max_experience
      ? ""
      : "This field is required.";
    tempErrors.locations =
      data.locations.length > 0 ? "" : "This field is required.";
    tempErrors.skills = data.skills.length > 0 ? "" : "This field is required.";
    tempErrors.job_position = data.job_position
      ? ""
      : "This field is required.";
    tempErrors.job_ctc = data.job_ctc ? "" : "This field is required.";
    tempErrors.job_details = data.job_details ? "" : "This field is required.";
    tempErrors.job_types =
      data.job_types.length > 0 ? "" : "This field is required.";
    setErrors(tempErrors);
    console.log("tempErrors", tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = async () => {
    if (validate()) {
      setLoading(true);
      try {
        const updatedData = {
          ...data,
          skills: data.skills.join(","),
          locations: data.locations.join(","),
          job_types: data.job_types.join(","),
          status: data.published,
        };
        let res = await addNewJob(updatedData);
        console.log("response", res);
        if (res !== false) {
          if (res?.error && res.error !== "undefined") {
            console.log("error", res.error);
            swal("Error", res.error, "error");
          } else {
            swal("Success", "Job added successfully", "success");
            navigate("/job_list");
          }
        } else {
          swal(
            "Error",
            "There was an error creating the job. Please contact administrator!",
            "error"
          );
        }
      } catch (error) {
        swal("Error", "There was an error creating the job", "error");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="container mt-5">
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600 me-auto">{""}</h4>
        <Link to="/job_list" className="btn btn-primary me-3 btn-sm">
          <i className="fas fa-plus me-2"></i>Back to Listing
        </Link>
      </div>

      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Job Details</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="form-label">Recruiter</label>
                  <select
                    name="company_id"
                    className="form-select"
                    onChange={handleChange}
                    value={data.company_id}
                  >
                    <option value="">Select Recruiter</option>
                    {companies?.map((cmp) => (
                      <option key={cmp.id} value={cmp.id}>
                        {cmp.company_name}
                      </option>
                    ))}
                  </select>
                  {errors.company_id && (
                    <div className="text-danger">{errors.company_id}</div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Company Name</label>
                  <input
                    type="text"
                    name="company_name"
                    className="form-control"
                    onChange={handleChange}
                    value={data.company_name}
                  />
                  {errors.company_name && (
                    <div className="text-danger">{errors.company_name}</div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Job Title</label>
                  <input
                    type="text"
                    name="job_title"
                    className="form-control"
                    onChange={handleChange}
                    value={data.job_title}
                  />
                  {errors.job_title && (
                    <div className="text-danger">{errors.job_title}</div>
                  )}
                </div>

                <div className="col-md-6 mb-3">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Min Experience</label>
                      <input
                        type="number"
                        name="min_experience"
                        className="form-control"
                        onChange={handleChange}
                        value={data.min_experience}
                      />
                      {errors.min_experience && (
                        <div className="text-danger">
                          {errors.min_experience}
                        </div>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Max Experience</label>
                      <input
                        type="number"
                        name="max_experience"
                        className="form-control"
                        onChange={handleChange}
                        value={data.max_experience}
                      />
                      {errors.max_experience && (
                        <div className="text-danger">
                          {errors.max_experience}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Location</label>
                  <Select
                    isMulti
                    name="locations"
                    options={locations.map((location) => ({
                      value: location.id,
                      label: location.city_name,
                    }))}
                    className="basic-multi-select"
                    classNamePrefix="Select Location"
                    onChange={handleLocationsChange}
                  />
                  {errors.locations && (
                    <div className="text-danger">{errors.locations}</div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Skills</label>
                  <Select
                    isMulti
                    name="skills"
                    options={skills.map((skill) => ({
                      value: skill.id,
                      label: skill.skill_title,
                    }))}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleSkillsChange}
                  />
                  {errors.skills && (
                    <div className="text-danger">{errors.skills}</div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Job Position</label>
                  <input
                    type="text"
                    name="job_position"
                    className="form-control"
                    onChange={handleChange}
                    value={data.job_position}
                  />
                  {errors.job_position && (
                    <div className="text-danger">{errors.job_position}</div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Job CTC</label>
                  <input
                    type="text"
                    name="job_ctc"
                    className="form-control"
                    onChange={handleChange}
                    value={data.job_ctc}
                  />
                  {errors.job_ctc && (
                    <div className="text-danger">{errors.job_ctc}</div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Job Types</label>
                  <Select
                    isMulti
                    name="job_types"
                    options={job_types.map((job_type) => ({
                      value: job_type.id,
                      label: job_type.title,
                    }))}
                    className="basic-multi-select"
                    classNamePrefix="Select Job Types"
                    onChange={handleJobTypesChange}
                  />
                  {errors.job_types && (
                    <div className="text-danger">{errors.job_types}</div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Job Sector</label>
                  <div className="contact-name">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="job_sector"
                      value="1"
                      id="job_sector1"
                      //checked={data.job_sector == "1"}
                      defaultChecked={data.job_sector === "1"}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="statusActive">
                      Tech &nbsp;&nbsp;&nbsp;
                    </label>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="job_sector"
                      value="0"
                      id="job_sector2"
                      onChange={handleChange}
                      //checked={data.job_sector !== "1"}
                      defaultChecked={data.job_sector === "0"}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="statusInactive"
                    >
                      Non-Tech
                    </label>
                    <span className="validation-text"></span>
                    {errors.job_sector && (
                      <div className="text-danger">{errors.job_sector}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <label className="form-label">Job Details</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={data.job_details}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setData((prevState) => ({
                        ...prevState,
                        job_details: data,
                      }));
                    }}
                  />
                  {errors.job_details && (
                    <div className="text-danger">{errors.job_details}</div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Job Publish Schedule</label>
                  <DatePicker
                    selected={data.job_publish_schedule}
                    onChange={(date) =>
                      handleDateChange(date, "job_publish_schedule")
                    }
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Application Deadline</label>
                  <DatePicker
                    selected={data.application_deadline}
                    onChange={(date) =>
                      handleDateChange(date, "application_deadline")
                    }
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Add to Hot Jobs</label>
                  <div className="contact-name">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="add_to_hot_jobs"
                      value="1"
                      id="add_to_hot_jobs1"
                      checked={data.add_to_hot_jobs === "1"}
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="add_to_hot_jobs1"
                    >
                      Yes &nbsp;&nbsp;&nbsp;
                    </label>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="add_to_hot_jobs"
                      value="0"
                      id="add_to_hot_jobs2"
                      checked={data.add_to_hot_jobs === "0"}
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="add_to_hot_jobs2"
                    >
                      No
                    </label>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Show Popup: How Did You Know?
                  </label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="show_popup_how_did_you_know"
                      id="show_popup_how_did_you_know"
                      checked={data.show_popup_how_did_you_know}
                      onChange={(e) =>
                        setData((prevState) => ({
                          ...prevState,
                          show_popup_how_did_you_know: e.target.checked,
                        }))
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="show_popup_how_did_you_know"
                    >
                      Yes
                    </label>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Profile Image</label>
                  <input
                    type="file"
                    name="profile_image"
                    className="form-control"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Status</label>
                  <div className="contact-name">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="published"
                      value="1"
                      id="statusActive"
                      checked={data.published === "1"}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="statusActive">
                      Active &nbsp;&nbsp;&nbsp;
                    </label>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="published"
                      value="0"
                      id="statusInactive"
                      onChange={handleChange}
                      checked={data.published !== "1"}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="statusInactive"
                    >
                      Inactive
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center">
        <button
          type="submit"
          className="btn btn-primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "Loading..." : "Add Job"}
        </button>
      </div>
    </div>
  );
};

export default NewJobs;
