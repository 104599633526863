import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { EditRAPI, EditRecruiter } from "../../api/recruiter";
import { planListAPI } from "../../api/plan";
import { roleMenuForRecruiterAPI } from "../../api/role";
import swal from "sweetalert";

const EditR = (props) => {
  const { editModal, setEditModal, dataFetch, editid, setEditContactId } =
    props;
  const [editFormData, setEditFormData] = useState({});
  const [planlist, setPlanList] = useState([]);
  const [menulist, setMenuList] = useState([]);
  const [recruiterType, setRecruiterType] = useState([
    { id: 1, name: "Regular" },
    { id: 2, name: "Non-Regular" },
  ]);

  useEffect(() => {
    if (editid > 0) {
      updateFormData();
    }
  }, [editid]);

  const updateFormData = async () => {
    let [formValues] = await EditRAPI(editid);
    formValues.publish = formValues.status;

    console.log(formValues);
    

    let rtArr = formValues.recruiter_type?.split(",");
    let rtsArr = recruiterType.filter((s) => {
      return rtArr.includes(`${s.id}`) ;
    });

    setEditFormData({
      ...formValues,
      recruiter_type: rtsArr?.map((rt) => ({ value: rt.id, label: rt.name })),
      plan_expire_date: formValues.plan_expire_date.split(" ")[0],
    });

    //setEditFormData({ ...formValues });
    fetchData();
  };

  const fetchData = async () => {
    let plan = await planListAPI();
    setPlanList(plan);
    let mdata = await roleMenuForRecruiterAPI();
    setMenuList(mdata);
  };

  const handleEditFormChange = (event) => {
    const fieldName = event.target.getAttribute("name");
    const fieldValue =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    let updatedFormData = { ...editFormData, [fieldName]: fieldValue };

    if (
      fieldName === "general_unlock_quota" ||
      fieldName === "applicants_unlock_quota"
    ) {
      updatedFormData.total_unlock_quota =
        parseInt(updatedFormData.general_unlock_quota || 0) +
        parseInt(updatedFormData.applicants_unlock_quota || 0);
    }

    setEditFormData(updatedFormData);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const menuId = name;
    let updatedMenus = [...editFormData.menus];
    if (checked) {
      updatedMenus.push(menuId);
    } else {
      updatedMenus = updatedMenus.filter((menu) => menu !== menuId);
    }
    setEditFormData({ ...editFormData, menus: updatedMenus });
  };

  const handleRTChange = (selectedOptions) => {
    //setEditFormData({ ...editFormData, menus: updatedMenus });
    setEditFormData((prevState) => ({
      ...prevState,
      recruiter_type: selectedOptions,
    }));
  };

  const handleEditFormSubmit = async (event) => {
    event.preventDefault();
    let error = false;
    let errorMsg = "";

    if (
      !editFormData.name ||
      !editFormData.company_name ||
      !editFormData.email ||
      !editFormData.recruiter_type ||
      editFormData.menus.length === 0
    ) {
      error = true;
      errorMsg = "Please fill in all required fields";
    }

    if (!error) {
      editFormData.recruiter_type = editFormData.recruiter_type
        .map((rt) => rt.value)
        .join(",");
      editFormData.status = editFormData.publish;
      let res = await EditRecruiter(editFormData, editid);

      if (res != false) {
        if (res?.error) {
          swal("Oops", res?.error, "error");
        } else {
          dataFetch();
          setEditContactId(null);
          setEditFormData({});
          setEditModal(false);
          swal(
            "Successfully Updated Event!",
            "Successfully Updated",
            "success"
          );
        }
      } else {
        swal(
          "Oops",
          "Some problem occurs. Please contact administrator.",
          "error"
        );
      }
    } else {
      swal("Oops", errorMsg, "error");
    }
  };

  const isFormValid = () => {
    return (
      editFormData.name &&
      editFormData.company_name &&
      editFormData.email &&
      editFormData.recruiter_type &&
      editFormData.menus.length > 0
    );
  };

  return (
    <Modal
      className="modal fade bd-example-modal-lg"
      show={editModal}
      onHide={() => setEditModal(false)}
      centered
      size="lg"
    >
      <div role="document">
        <div className="">
          <form>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Update Recruiter</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setEditModal(false)}
                data-dismiss="modal"
              >
                <span></span>
              </button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-black font-w500">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={editFormData.name || ""}
                          onChange={handleEditFormChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Company Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="company_name"
                          value={editFormData.company_name || ""}
                          onChange={handleEditFormChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-black font-w500">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          value={editFormData.email || ""}
                          onChange={handleEditFormChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-black font-w500">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          value={editFormData.password || ""}
                          onChange={handleEditFormChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Plan Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="planname"
                          value={editFormData.plan_name || ""}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Plan Expiry Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          autoComplete="off"
                          name="plan_expire_date"
                          value={editFormData.plan_expire_date || ""}
                          onChange={handleEditFormChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 my-2">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Actively hiring
                        </label>
                        <select
                          className="form-select"
                          name="actively_hiring"
                          value={editFormData.actively_hiring || ""}
                          onChange={handleEditFormChange}
                        >
                          <option value="">Select </option>
                          <option value={1}>Yes</option>
                          <option value={0}>No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="text-black font-w500">Menus</label>
                        <div className="form-check">
                          {menulist.map((menu, index) => (
                            <div
                              key={menu.id}
                              className="form-check"
                              style={{
                                display: "inline-block",
                                width: "48%",
                                marginRight: "4%",
                              }}
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name={menu.id.toString()}
                                checked={
                                  editFormData.menus?.includes(
                                    menu.id.toString()
                                  ) || false
                                }
                                onChange={handleCheckboxChange}
                              />
                              <label className="form-check-label">
                                {menu.menu_name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <div className="form-group mt-2">
                        <label className="text-black font-w500">
                          Total Unlock Quota
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="total_unlock_quota"
                          value={editFormData.total_unlock_quota || ""}
                          onChange={handleEditFormChange}
                          readOnly
                        />
                      </div>
                      <div className="form-group mt-2">
                        <label className="text-black font-w500">
                          General Unlock Quota
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="general_unlock_quota"
                          value={editFormData.general_unlock_quota || ""}
                          onChange={handleEditFormChange}
                        />
                      </div>
                      <div className="form-group mt-2">
                        <label className="text-black font-w500">
                          Applicants Unlock Quota
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="applicants_unlock_quota"
                          value={editFormData.applicants_unlock_quota || ""}
                          onChange={handleEditFormChange}
                        />
                      </div>
                      <div className="form-group mt-2">
                        <label className="text-black font-w500">
                          Jobs Limit
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="jobs_limit"
                          value={editFormData.jobs_limit || ""}
                          onChange={handleEditFormChange}
                        />
                      </div>
                      <div className="form-group mt-2">
                        <label className="text-black font-w500">
                          Sub Recruiter Quota
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="sub_recruiter_quota"
                          value={editFormData.sub_recruiter_quota || ""}
                          onChange={handleEditFormChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mt-2">
                        <label className="text-black font-w500">
                          Total Unlocked Used
                        </label>
                        <label className="form-control">
                          {editFormData.total_unlocked_used || 0}
                        </label>
                      </div>
                      <div className="form-group mt-2">
                        <label className="text-black font-w500">
                          General Unlock Used
                        </label>
                        <label className="form-control">
                          {editFormData.general_unlock_used || 0}
                        </label>
                      </div>
                      <div className="form-group mt-2">
                        <label className="text-black font-w500">
                          Applicants Unlock Used
                        </label>
                        <label className="form-control">
                          {editFormData.applicants_unlock_used || 0}
                        </label>
                      </div>
                      <div className="form-group mt-2">
                        <label className="text-black font-w500">
                          Jobs Limit Used
                        </label>
                        <label className="form-control">
                          {editFormData.jobs_limit_used || 0}
                        </label>
                      </div>
                      <div className="form-group mt-2">
                        <label className="text-black font-w500">
                          Sub Recruiter Quota Used
                        </label>
                        <label className="form-control">
                          {editFormData.sub_recruiter_quota_used || 0}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Publish Status
                        </label>
                        <div>
                          <input
                            type="radio"
                            className="form-check-input"
                            name="publish"
                            value="1"
                            checked={editFormData.publish == "1"}
                            onChange={handleEditFormChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="publishActive"
                          >
                            Active &nbsp;&nbsp;&nbsp;
                          </label>
                          <input
                            type="radio"
                            className="form-check-input"
                            name="publish"
                            value="0"
                            checked={editFormData.publish != "1"}
                            onChange={handleEditFormChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="publishInactive"
                          >
                            Inactive
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-success"
                onClick={handleEditFormSubmit}
                disabled={!isFormValid()}
              >
                Update
              </button>
              <button
                type="button"
                onClick={() => setEditModal(false)}
                className="btn btn-danger"
              >
                <i className="flaticon-delete-1"></i> Discard
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default EditR;
