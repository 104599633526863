import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import DataTable from "react-data-table-component";
//import { Link } from "react-router-dom";
import swal from "sweetalert";
import { getList } from "../../../services/SubRecruiterService";
import axiosInstance from "../../../services/AxiosInstance";
// import {
//   getDownlodedResume,
//   getProfileView,
//   getTimeLog,
//   getUnlock,
// } from "../../../services/SubRecruiterService";

export default function JobFairVarification() {
  const tableClm = useMemo(() => {
    return [
      {
        name: "Candidate ID",
        selector: (row) => row.candidate_id,
      },
      {
        name: "Status",
        cell: (row) => {
          if (row?.status === 0) {
            return <span className="badge bg-danger">Rejected</span>;
          } else if (row?.status === 1) {
            return <span className="badge bg-success">Accepted</span>;
          } else {
            return <span className="badge bg-warning">Pending</span>;
          }
        },
      },
      {
        name: "Candidate Name",
        selector: (row) => row.candidate_name,
        wrap: true,
        minwidth: "200px",
      },
      {
        name: "Email",
        selector: (row) => row.email,
        wrap: true,
        minwidth: "200px",
      },
      {
        name: "Phone",
        selector: (row) => row.phone_number,
        wrap: true,
        minwidth: "200px",
      },
      {
        name: "Datetime",
        selector: (row) => row.applied_date,
        wrap: true,
        minwidth: "200px",
      },
    ];
  }, []);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [selectedRow, setSelectedRows] = useState([]);

  const [columns] = useState(tableClm);

  const [reqParams, setReqParams] = useState({
    page: currentPage,
    limit: perPage,
    status: "",
  });

  const [fetch, setFetch] = useState(true);

  const fetchUsers = useCallback(
    (params) => {
      setToggleCleared(true);
      setLoading(true);
      getList(params)
        .then((res) => {
          setData(res?.data?.data);
          setTotalRows(res?.data?.totalRecords);
          setLoading(false);
          setFetch(false);
        })
        .catch((err) => {
          swal("Error", err?.message, "error");
          setLoading(false);
          setFetch(false);
        });
    },
    [setFetch]
  );

  useEffect(() => {
    if (fetch) {
      fetchUsers(reqParams);
    }
  }, [fetch, reqParams, fetchUsers]);

  const handleDelete = useCallback(() => {
    const candidate = selectedRow.map((c, i) => {
      return c.candidate_id;
    });

    if (candidate?.length < 1) {
      swal("Opps", "Please select at least 1 candidate to delete.", "error");
      return false;
    }

    axiosInstance
      .post("/admin/candidate/delete-job-fair-candidates", {
        candidateIds: candidate,
      })
      .then((resp) => {
        if (resp?.data.code === "success") {
          swal("Great Job!", resp?.data.message, "success");
          setToggleCleared(true);
          setFetch(true);
        } else if (resp?.data.code === "error") {
          setToggleCleared(true);
          swal("Opps", resp?.data.message, "error");
        }
      })
      .catch((error) => {
        setToggleCleared(true);
        swal("Opps", error?.response?.data?.message, "error");
      });
  }, [selectedRow, setFetch, setToggleCleared]);



  const handleAcceptReject = useCallback(
    (e, status) => {
      const candidate = selectedRow.map((c, i) => {
        return c.candidate_id;
      });

      if (candidate?.length < 1) {
        e.target.value = "";
        swal(
          "Opps",
          `Please select at least 1 candidate to ${
            status === "1" ? "Accept" : "Reject"
          }.`,
          "error"
        );
        return false;
      }

      let url = `/admin/candidate/${
        status === "1" ? "activate" : "deactivate"
      }-job-fair-candidates`;

      axiosInstance
        .post(url, {
          candidateIds: candidate,
        })
        .then((resp) => {
          if (resp?.data.code === "success") {
            e.target.value = "";
            swal("Great Job!", resp?.data.message, "success");
            setToggleCleared(!toggleCleared);
            setSelectedRows([]);
            setFetch(true);
          } else if (resp?.data.code === "error") {
            setToggleCleared(!toggleCleared);
            swal("Opps", resp?.data.message, "error");
          }
        })
        .catch((error) => {
          setToggleCleared(!toggleCleared);
          swal("Opps", error?.response?.data?.message, "error");
        });
    },
    [selectedRow,toggleCleared, setFetch, setToggleCleared]
  );

  console.log(selectedRow);
  

  const handlePageChange = useCallback(
    (page) => {
      setCurrentPage(page);
      setReqParams({
        ...reqParams,
        page: page,
      });
      setFetch(true);
    },
    [setReqParams, reqParams, setFetch]
  );

  const handlePerRowsChange = useCallback(
    async (newPerPage, page) => {
      setPerPage(newPerPage);
      setReqParams({
        ...reqParams,
        limit: newPerPage,
      });
      setFetch(true);
    },
    [setReqParams, reqParams, setFetch]
  );

  const handleTabSelect = useCallback(
    (key) => {
      if (key === "accepted") {
        fetchUsers({ ...reqParams, status: 1 });
      } else if (key === "rejected") {
        fetchUsers({ ...reqParams, status: 0 });
      } else {
        fetchUsers({ ...reqParams, status: "" });
      }
    },
    [fetchUsers, reqParams]
  );

  return (
    <div className="card">
      <div className="card-body">
        <Tab.Container
          defaultActiveKey="all"
          onSelect={(e) => handleTabSelect(e)}
        >
          <Nav
            as="ul"
            variant="pills"
            className="nav  flex-row justify-content-around mt-3"
            role="tablist"
          >
            <Nav.Item as="li" className="fs-5">
              <Nav.Link as="a" eventKey="all" to="#all">
                All candidates
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="fs-5">
              <Nav.Link as="a" eventKey="accepted" to="#all">
                Accepted candidates
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="fs-5">
              <Nav.Link as="a" eventKey="rejected" to="#all">
                Rejected candidates
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <hr className="my-3" />
          <div className="d-flex align-items-center mb-4 flex-wrap">
            <select
              onChange={(e) => handleAcceptReject(e, e.target.value)}
              className="btn btn-warning btn-sm me-2"
            >
              <option value="">Status</option>
              <option value={1}>Accept</option>
              <option value={0}>Reject</option>
            </select>

            <button
              onClick={handleDelete}
              className="btn btn-danger me-3 btn-sm"
            >
              <i className="fas fa-trash me-1"></i> Delete
            </button>
          </div>
          <DataTable
            className="table display dataTablesCard job-table table-responsive-xl card-table dataTable no-footer"
            columns={columns}
            data={data}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationRowsPerPageOptions={[10, 20, 30, 50, 100, 200, 500]}
            onChangePage={handlePageChange}
            selectableRows
            persistTableHead
            onSelectedRowsChange={({ selectedRows }) =>
              setSelectedRows(selectedRows)
            }
            clearSelectedRows={toggleCleared}
            //onSort={handleSort}
            sortServer
            fixedHeader={true}
              fixedHeaderScrollHeight={'500px'}
          />
        </Tab.Container>
      </div>
    </div>
  );
}
