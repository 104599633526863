import React, { useState } from 'react';
import swal from "sweetalert";
import CkEditorBlog from '../Forms/CkEditor/CkEditorBlog';
import { addNewNotification } from "../../api/notification";
import { useNavigate, Link } from 'react-router-dom';
import { uploadImage } from "../../api/common";

const initialState = {
    "ntype": "",
    "subject": "",
    "notification_body": "",
    "attachment": "",
    "published": 0,
};

const AddNotification = () => {
    const [data, setData] = useState(initialState);
    const [file, setFile] = useState(null);
    const [notificationTypes] = useState([{ ntype: 1, title: "whatsapp" }, { ntype: 2, title: "Email" }]);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const handleEditorChange = (value) => {
        setData({
            ...data,
            notification_body: value
        });
    };

   // const handleFileChange = (e) => {
     //   setFile(e.target.files[0]);
    //};

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
		const formData = new FormData();
        if (file) {
            formData.append('file', file);
            const response = await uploadImage(formData);
            if (response!=false) {
                setData({ ...data, attachment: response.data });
                //swal("Success", "Image uploaded successfully", "success");
            } else {
                swal("Error", "Image upload failed", "error");
            }
        }
    };

    const handleSubmit = async () => {
        let updatedData = { ...data };
        updatedData.status = updatedData.published;
        try {
           let res =  await addNewNotification(updatedData);
           if(res!=false){
            swal('Successfully Created Notification!', 'Successfully Added', "success");
            navigate('/notification');
           }else{
            swal('Error', 'There was an error creating the notification', "error");
           }
        } catch (error) {
            swal('Error', 'There was an error creating the notification', "error");
        }
    };

    return (
        <>
            <div className="d-flex align-items-center mb-4 flex-wrap">
                <h4 className="fs-20 font-w600 me-auto">{""}</h4>
                <Link to="/notification" className="btn btn-primary me-3 btn-sm">
                        <i className="fas fa-plus me-2"></i>Back to Listing
                </Link>
            </div>

            <div className="row">
                <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Notification Type</h4>
                        </div>
                        <div className="card-body">
                            <select name="ntype" className="form-control" value={data.ntype} onChange={handleChange}>
                                <option value="">Select Notification Type</option>
                                {notificationTypes.map((type) => (
                                    <option key={type.ntype} value={type.ntype}>{type.title}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Subject</h4>
                        </div>
                        <div className="card-body">
                            <input type="text" name="subject" className="form-control" value={data.subject} onChange={handleChange} placeholder="Subject" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Notification Body</h4>
                        </div>
                        <div className="card-body custom-ekeditor">
                            <CkEditorBlog data={data.notification_body} updateEditor={handleEditorChange} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Attachment</h4>
                        </div>
                        <div className="card-body">
                            <input type="file" name="attachment" className="form-control" onChange={handleFileChange} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Published</h4>
                        </div>
                        <div className="card-body">
                            <div className="form-check">
                                <input type="radio" name="published" value={1} checked={data.published == 1} onChange={handleChange} className="form-check-input" />
                                <label className="form-check-label">Active</label>
                            </div>
                            <div className="form-check">
                                <input type="radio" name="published" value={0} checked={data.published != 1} onChange={handleChange} className="form-check-input" />
                                <label className="form-check-label">Inactive</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Add</button>
            </div>
        </>
    );
};

export default AddNotification;