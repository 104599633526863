import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import * as XLSX from "xlsx"; // Import for Excel download
import {
  jobListAPI,
  deleteJob,
  jobCompanyList,
  verifyThisJob,
} from "../../api/job";
import { getAllSkills } from "../../api/common";
import useAccess from "../../api/useAccess";
import { job_types } from "../../common/job_types";
import { getReasonList } from "../../api/candidate";

const useQuery = () => new URLSearchParams(useLocation().search);

const JobList = () => {
  const query = useQuery();
  const paramValue = query.get("type");
  const navigate = useNavigate();
  const { fetchAccess } = useAccess();
  const permission = fetchAccess();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10); // State for rows per page
  const [hasMore, setHasMore] = useState(true);
  const [totalEntries, setTotalEntries] = useState(0);
  const [filters, setFilters] = useState({
    company_id: "",
    job_title: "",
    location: "",
    skill_id: "",
    job_type: "",
    job_sector: "",
  });
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [reason, setReason] = useState([]);

  //const rowsPerPage = rowsPerPage;
  const recruiter_id =
    paramValue === "recruiter" ? localStorage.getItem("recruiter_id") : "";
  const subrecruiter_id =
    paramValue === "subrecruiter"
      ? localStorage.getItem("subrecruiter_id")
      : "";

  const fetchFilterData = async () => {
    setSkillList(await getAllSkills());
    setCompanies(await jobCompanyList());
  };

  const dataFetch = async (reset = false) => {
    try {
      const params = {
        page,
        limit: rowsPerPage,
        ...filters,
        sort_by: sortConfig.key,
        sort_direction: sortConfig.direction,
        recruiter_id,
        subrecruiter_id,
      };

      const response = await jobListAPI(params);
      setTotalEntries(response.total || 1);
      setHasMore(response.data.length >= rowsPerPage);
      setData(reset ? response.data : [...data, ...response.data]);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setHasMore(false);
    }
  };

  useEffect(() => {
    dataFetch(true);
  }, [filters, sortConfig, page, rowsPerPage]);

  const fetchReason = useCallback(() => {
    getReasonList(3)
      .then((res) => {
        setReason(res);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetchReason();
    fetchFilterData();
  }, []);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
    setPage(1);
  };

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const handleCheckboxChange = (id) => {
    setSelectedJobs((prev) =>
      prev.includes(id) ? prev.filter((jobId) => jobId !== id) : [...prev, id]
    );
  };

  //   const handleDeleteClick = async (id) => {
  //     const willDelete = await swal({
  //       title: "Are you sure?",
  //       text: "This record will be deleted.",
  //       icon: "warning",
  //       buttons: true,
  //       dangerMode: true,
  //     });

  //     if (willDelete) {
  //       await deleteJob(id);
  //       dataFetch(true);
  //       swal("Deleted!", "Record has been deleted.", "success");
  //     }
  //   };

  const reasonOptionList = useCallback(() => {
    var span = document.createElement("span");
    let list = "<option value=''>Select Reason</option>";
    if (reason) {
      for (let index = 0; index < reason.length; index++) {
        const res = reason[index];
        list +=
          `<option value="` +
          res.id +
          `" key="` +
          index +
          `"}>` +
          res.reason +
          `</option>`;
      }
    }
    span.innerHTML =
      `<select id="reason" class="form-control">` + list + `</select>`;
    return span;
  }, [reason]);

  // delete data
  const handleDeleteClick = async (contactId) => {
    var candidate;
    if (contactId) {
      candidate = [contactId];
    } else {
      candidate = selectedJobs;
    }
    if (candidate.length < 1) {
      swal("Opps", "Please select at least 1 job to delete.", "error");
      return false;
    }
    const span = reasonOptionList();
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this record?",
      icon: "warning",
      dangerMode: true,
      buttons: true,
      content: span,
    });

    if (willDelete) {
      let postData = {
        reason_id: document.getElementById("reason").value,
        ids: candidate.join(","),
      };
      //await deleteUser(contactId);
      await deleteJob(postData);
      setSelectAll(false);
      setSelectedJobs([]);
      dataFetch(true);
      swal("Deleted!", "Record has been deleted!", "success");
    }
  };

  const handleEditClick = async (event, id) => {
    event.preventDefault();
    localStorage.setItem("nid", id);
    navigate("/edit_Job");
  };

  const handleViewClick = async (event, id) => {
    event.preventDefault();
    localStorage.setItem("vid", id);
    navigate("/view_job");
  };

  const handleApplicantsClick = (e, job_id) => {
    e.preventDefault();
    localStorage.setItem("job_id", job_id);
    localStorage.removeItem("event_id");
    navigate("/c_lists?type=job");
  };

  const verifyThisUser = async (id) => {
    //setIsLoading(true);
    try {
      const updatedData = {
        verified_status: 1,
        id: id,
        recruiter_id: recruiter_id,
      };
      let res = await verifyThisJob(updatedData);
      if (res !== false) {
        if (res?.error && res.error !== "undefined") {
          console.log("error", res.error);
          swal("Error", res.error, "error");
        } else {
          //setPage(0);
          dataFetch(true);
          swal("Success", "Job updated successfully", "success");
        }
      } else {
        swal(
          "Error",
          "There was an error updating the job. Please contact administrator!",
          "error"
        );
      }
    } catch (error) {
      swal("Error", "There was an error updating the job", "error");
    } finally {
      //setIsLoading(false);
    }
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    setSelectedJobs(isChecked ? data.map((job) => job.id) : []);
  };

  const handleVerifySelected = async (verifiedStatus) => {
    if (selectedJobs.length === 0) {
      swal("No jobs selected", "Please select jobs to update.", "info");
      return;
    }

    try {
      await Promise.all(
        selectedJobs.map((id) =>
          verifyThisJob({ id, verified_status: verifiedStatus })
        )
      );
      swal(
        "Success",
        `Jobs have been ${verifiedStatus ? "verified" : "unverified"}.`,
        "success"
      );
      dataFetch(true); // Refresh the list
      setSelectedJobs([]);
      setSelectAll(false);
    } catch (error) {
      console.error("Error updating jobs:", error);
      swal("Error", "Could not update selected jobs.", "error");
    }
  };

  const handleCopy = () => {
    const text = data.map((job) => Object.values(job).join(", ")).join("\n");
    navigator.clipboard.writeText(text).then(() => {
      swal("Copied!", "Table data copied to clipboard.", "success");
    });
  };

  const handleDownloadExcel = () => {
    const displayedData = data.map((job) => ({
      "Job Code": job.id,
      "Company Name": job.company_name,
      "Job Title": job.job_title,
      Location: job.locations,
      "Created Time": job.created_at,
      "Created By": job?.created_by_name || "N/A", // Handle creator name
      "Updated Time": job.updated_at || "N/A", // Handle updated time
      "Job Publishing Schedule": job.job_publish_schedule || "N/A", // Publishing schedule
      Deadline: job.application_deadline || "N/A", // Application deadline
      "Active Status": job.published == 1 ? "Active" : "Inactive", // Published status
      "Add to Hot Jobs": job.add_to_hot_jobs == "1" ? "Yes" : "No", // Hot job status
      "Show Popup": job.show_popup_how_did_you_know == "1" ? "Yes" : "No", // Popup flag
    }));

    const worksheet = XLSX.utils.json_to_sheet(displayedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Jobs");
    XLSX.writeFile(workbook, "JobList.xlsx");
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(1); // Reset to first page on rows change
    // dataFetch(true);
  };

  const isVerifiedUserHTML = (job) =>
    job.verified_status ? (
      <button className="btn btn-success">Verified</button>
    ) : (
      <button
        className="btn btn-primary"
        onClick={() => verifyThisUser(job.id)}
      >
        Verify
      </button>
    );

  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? <span>▲</span> : <span>▼</span>;
    }
    return <span>↕</span>;
  };

  const renderPagination = () => (
    <div className="d-md-flex d-block align-items-center justify-content-between text-center flex-wrap mt-md-0 mt-3">
      <div className="mb-md-0 mb-2">
        <h5 className="mb-0">
          Showing {Math.min((page - 1) * rowsPerPage + 1, totalEntries)} to{" "}
          {Math.min(page * rowsPerPage, totalEntries)} of {totalEntries} entries
        </h5>
      </div>
      <nav>
        <ul className="pagination pagination-circle justify-content-center">
          <li className="page-item page-indicator">
            <Link
              to="#"
              className="page-link"
              onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
              disabled={page === 1}
            >
              <i className="fa fa-angle-double-left" />
            </Link>
          </li>
          <li className="page-item active">
            <Link to="#" className="page-link">
              {page}
            </Link>
          </li>
          <li className="page-item page-indicator">
            <Link
              to="#"
              className="page-link"
              onClick={() => setPage((prev) => (hasMore ? prev + 1 : prev))}
              disabled={!hasMore}
            >
              <i className="fa fa-angle-double-right" />
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600 me-auto">{""}</h4>
        {paramValue == null && permission.a && (
          <Link to="/new_Job" className="btn btn-primary btn-sm me-3">
            <i className="fas fa-plus me-2"></i>Add New Job
          </Link>
        )}

        <button
          className="btn btn-success ms-2"
          onClick={() => handleVerifySelected(1)}
        >
          Verify Selected
        </button>
        <button
          className="btn btn-warning ms-2"
          onClick={() => handleVerifySelected(0)}
        >
          Unverify Selected
        </button>
      </div>
      <div className="d-flex justify-content-end mb-4">
        <div className="d-flex gap-3">
          <select
            className="form-select"
            name="company_id"
            value={filters.company_id}
            onChange={handleFilterChange}
          >
            <option value="">Select Company</option>
            {companies.map((company) => (
              <option key={company.id} value={company.id}>
                {company.company_name}
              </option>
            ))}
          </select>
          {/* <select
                        className="form-select"
                        name="location"
                        value={filters.location}
                        onChange={handleFilterChange}
                    >
                        <option value="">Select Location</option>
                        {citiesList.map(city => (
                            <option key={city.id} value={city.id}>{city.city_name}</option>
                        ))}
                    </select>
                    */}
          <select
            className="form-select"
            name="skill_id"
            value={filters.skill_id}
            onChange={handleFilterChange}
          >
            <option value="">Filter by Skills</option>
            {skillList?.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.skill_title}
                </option>
              );
            })}
          </select>
          <select
            className="form-select"
            name="job_type"
            value={filters.job_type}
            onChange={handleFilterChange}
          >
            <option value="">Select Job Type</option>
            {job_types.map((jobType, index) => (
              <option key={index} value={jobType.id}>
                {jobType.title}
              </option>
            ))}
          </select>
          <select
            className="form-select"
            name="job_sector"
            value={filters.job_sector}
            onChange={handleFilterChange}
          >
            <option value="">Select Job Sector</option>
            <option value="1">Tech</option>
            <option value="0">Non-tech</option>
          </select>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12">
          <div className="row mb-2">
            <div className="col-xl-10">
              <button
                className="btn btn-danger btn-sm me-3"
                onClick={() => handleDeleteClick("")}
              >
                <i className="fa fa-trash"></i> Delete
              </button>
              <button
                className="btn btn-secondary btn-sm me-3"
                onClick={handleCopy}
              >
                COPY
              </button>
              <button
                className="btn btn-warning btn-sm"
                onClick={handleDownloadExcel}
              >
                Download Excel
              </button>
            </div>
            <div className="col-xl-2">
              <select
                className="form-select w-100 me-3"
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
              >
                {[10, 25, 50, 100, 1000, 2000].map((size) => (
                  <option key={size} value={size}>
                    Show {size}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table display mb-4 dataTablesCard Plan-table table-responsive-xl card-table dataTable no-footer">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </th>
                  <th onClick={() => handleSort("verified_status")}>
                    Verification{renderSortIcon("verified_status")}
                  </th>
                  <th onClick={() => handleSort("id")}>
                    Job Code{renderSortIcon("id")}
                  </th>
                  <th onClick={() => handleSort("company_name")}>
                    Company Name {renderSortIcon("company_name")}
                  </th>
                  <th onClick={() => handleSort("job_title")}>
                    Job Title {renderSortIcon("job_title")}
                  </th>
                  <th>Location</th>
                  <th onClick={() => handleSort("job_sector")}>
                    Job Sector {renderSortIcon("job_sector")}
                  </th>

                  <th onClick={() => handleSort("created_at")}>
                    Created Time{renderSortIcon("created_at")}
                  </th>

                  <th onClick={() => handleSort("created_by_name")}>
                    Created By{renderSortIcon("created_by_name")}
                  </th>
                  <th onClick={() => handleSort("updated_at")}>
                    Updated Time{renderSortIcon("updated_at")}
                  </th>
                  <th onClick={() => handleSort("job_publish_schedule")}>
                    Job Publishing schedule
                    {renderSortIcon("job_publish_schedule")}
                  </th>
                  <th onClick={() => handleSort("application_deadline")}>
                    Deadline{renderSortIcon("application_deadline")}
                  </th>
                  <th onClick={() => handleSort("published")}>
                    Active status{renderSortIcon("published")}
                  </th>
                  <th onClick={() => handleSort("add_to_hot_jobs")}>
                    Add to Hot Jobs
                    {renderSortIcon("add_to_hot_jobs")}
                  </th>
                  <th onClick={() => handleSort("show_popup_how_did_you_know")}>
                    Show Popup
                    {renderSortIcon("show_popup_how_did_you_know")}
                  </th>

                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((job, index) => (
                    <tr key={job.id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedJobs.includes(job.id)}
                          onChange={() => handleCheckboxChange(job.id)}
                        />
                      </td>
                      <td>{isVerifiedUserHTML(job)}</td>
                      <td>{job.id}</td>
                      <td>{job.company_name}</td>
                      <td>{job.job_title}</td>
                      <td>{job.locations}</td>
                      <td>{job.job_sector === 1 ? "Tech" : "Non-tech"}</td>
                      <td>{job.created_at}</td>
                      <td>{job?.created_by_name}</td>
                      <td>{job.updated_at}</td>
                      <td>{job.job_publish_schedule}</td>
                      <td>{job.application_deadline}</td>
                      <td>{job.published == 1 ? "Active" : "InActive"}</td>
                      <td>{job.add_to_hot_jobs == "1" ? "Yes" : "No"}</td>
                      <td>
                        {job.show_popup_how_did_you_know == "1" ? "Yes" : "No"}
                      </td>

                      {permission.v ? (
                        <td>
                          <div className="d-flex justify-content-end">
                            <div>
                              <Link
                                to={"#"}
                                className="btn btn-secondary light mr-2"
                                onClick={(e) =>
                                  handleApplicantsClick(e, job.id)
                                }
                              >
                                Applicants
                              </Link>
                            </div>
                            <div className="action-buttons d-flex justify-content-end">
                              <a
                                href={`${process.env.REACT_APP_FRONTEND}jobs/job-detail/${job.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-secondary shadow btn-xs sharp me-1"
                              >
                                <i className="fas fa-eye"></i>
                              </a>

                              <Link
                                onClick={(event) =>
                                  handleEditClick(event, job.id)
                                }
                                className="btn btn-primary shadow btn-xs sharp me-1"
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </Link>
                              <Link
                                onClick={() => handleDeleteClick(job.id)}
                                className="btn btn-danger shadow btn-xs sharp"
                              >
                                <i className="fas fa-trash-alt"></i>
                              </Link>
                            </div>
                          </div>
                        </td>
                      ) : null}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No Records Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {renderPagination()}
    </>
  );
};

export default JobList;
