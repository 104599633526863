import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useParams } from "react-router-dom";

//import user from "./../../../images/user.jpg";
import {
  addNotes,
  getCandidateViewNotes,
  getViewCandidate,
} from "../../../services/CandidateService";
import CommentComponent from "../App/CandidateProfile/CommentComponent";
//import { store } from "../../../store/store";

const UserProfileAdmin = () => {
  const params = useParams();
  const [profile, setProfile] = useState({});
  const [comments, setComments] = useState([]);

  //const [favourite, setFavourite] = useState([]);
  //const [unlock, setUnlock] = useState([]);
  //const [usertype, setUsertype] = useState("");
  //const [finalStatus, setFinalStatus] = useState("");
  const noteRef = useRef(null);
  const statusRef = useRef(null);

  const candidateStatus = useMemo(() => {
    return [
      { value: 1, title: "CV Shortlisted" },
      { value: 2, title: "Reject" },
      { value: 3, title: "On hold" },
      { value: 4, title: "Offered" },
      { value: 5, title: "DND" },
      { value: 6, title: "Others" },
      { value: 7, title: "In process" },
      { value: 8, title: "Interview Scheduled" },
      { value: 9, title: "Viewed(Action Pending)" },
    ];
  }, []);

  const handleGetNotes = useCallback((id) => {
    getCandidateViewNotes(id)
      .then((res) => {
        if (res?.data?.data !== undefined) {
          setComments(res?.data?.data.reverse());
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const fetchProfile = useCallback(() => {
    getViewCandidate(params?.id)
      .then((resp) => {
        setProfile(resp?.data?.data);
        //setUsertype(resp?.data?.commentFlagStatus);
        //   if (resp?.data?.comments[0] !== undefined) {
        //     setComments(resp?.data?.comments[0].reverse());
        //   }
        handleGetNotes(String(params?.id));
      })
      .catch((error) => console.log(error?.message));
  }, [params, handleGetNotes]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  useEffect(() => {
    let element = document.getElementById("comments-container");
    if (element) {
      element.scrollTo(0, element?.scrollHeight);
    }
  }, [comments]);

  const handleAddNotes = useCallback(
    (e) => {
      e.preventDefault();
      let data = {
        candidate_id: String(profile?.id),
        notes: noteRef.current.value,
        profileStatus: statusRef.current.value,
      };

      addNotes(data)
        .then((res) => {
          noteRef.current.value = "";
          statusRef.current.value = "";
          handleGetNotes(String(profile?.id));
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    [profile]
  );

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <h3 className="mb-0 me-auto">User Profile</h3>
        <div>
          <Link to={"/c_lists"} className="btn btn-warning btn-sm me-3">
            Back
          </Link>
        </div>
        {/* <div>
          <Link to={"#"} className="btn btn-secondary btn-sm me-3">
            <i className="fas fa-envelope"></i>
          </Link>
          <Link to={"#"} className="btn btn-secondary btn-sm me-3">
            <i className="fas fa-phone-alt"></i>
          </Link>
          <Link to={"#"} className="btn btn-primary btn-sm">
            <i className="fas fa-info"></i>
          </Link>
        </div> */}
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header border-0 flex-wrap align-items-start">
              <div className="col-md-8">
                <div className="user d-sm-flex d-block pe-md-5 pe-0">
                  {/* <img src={user} alt="" /> */}
                  <i className="fa fa-user-circle fa-6x"></i>
                  <div className="ms-sm-3 ms-0 me-md-5 md-0">
                    {profile?.preferred_name && (
                      <h5 className="mb-1 font-w600">
                        <Link to={"#"} onClick={(e) => e.preventDefault()}>
                          {profile?.preferred_name}
                        </Link>
                      </h5>
                    )}
                    {profile?.id && (
                      <h5 className="mb-1 font-w600">
                        <Link
                          className="pe-none"
                          onClick={(e) => e.preventDefault()}
                        >
                          {/* Candidate #{profile?.id}
                          <br /> */}
                          ID: {profile?.id}
                        </Link>
                      </h5>
                    )}
                    {profile?.identify_name && (
                      <h5 className="mb-1 font-w600">
                        {/* <Link onClick={(e) => e.preventDefault()}> */}
                        <span className="badge bg-primary">
                          {profile?.identify_name}
                        </span>
                        {/* </Link> */}
                        {profile?.pronoun && (
                          //   <Link onClick={(e) => e.preventDefault()}>
                          <span className="ms-2 badge bg-primary">
                            {profile?.pronoun}
                          </span>
                          //   </Link>
                        )}
                      </h5>
                    )}
                    <div className="listline-wrapper mb-2">
                      {profile?.email && (
                        <span className="item">
                          <i className="text-primary far fa-envelope"></i>
                          {profile?.email ?? "demo@email.com"}
                        </span>
                      )}
                      {profile?.current_profile_role && (
                        <span className="item">
                          <i className="text-primary far fa-id-badge"></i>
                          {profile?.current_profile_role ?? ""}
                        </span>
                      )}
                      {profile?.city_name && (
                        <span className="item">
                          <i className="text-primary fas fa-map-marker-alt"></i>
                          {profile?.city_name ?? ""}
                        </span>
                      )}
                    </div>
                    {profile?.about_yourself && (
                      <p>{profile?.about_yourself ?? "NA"}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12 text-end">
                {profile?.resume_file && (
                  <Link
                    to={profile?.resume_file}
                    target="_blank"
                    className="btn btn-primary btn-sm me-2  mb-2"
                  >
                    <i className="fas fa-download me-2"></i>Download Resume
                  </Link>
                )}
                {/* <Link to={"#"} className="btn btn-sm btn-primary me-2">
                  Ask
                </Link>
                <Link to={"#"} className="btn btn-sm btn-info">
                  Hire
                </Link> 
                 <div className="mt-3">
                  <h6 className="text-start">
                    Progress
                    <span className="float-end">85%</span>
                  </h6>
                  <div className="progress ">
                    <div
                      className="progress-bar bg-danger progress-animated"
                      style={{ width: "85%", height: "6px" }}
                      role="progressbar"
                    >
                      <span className="sr-only">60% Complete</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="card-body pt-0">
              <h4 className="fs-20">Description</h4>
              <div className="row">
                <div className="col-xl-6 col-md-6">
                  {profile?.preferred_name && (
                    <p className="font-w600 mb-2 d-flex">
                      <span className="custom-label-2">Full Name : </span>
                      <span className="font-w400">
                        {profile?.preferred_name ?? ""}
                      </span>
                    </p>
                  )}
                  {/* {profile?.current_profile_role && (
                    <p className="font-w600 mb-2 d-flex">
                      <span className="custom-label-2">Resume Title : </span>
                      <span className="font-w400">
                        {profile?.current_profile_role ?? ""}
                      </span>
                    </p>
                  )} */}
                  {profile?.current_profile_role && (
                    <p className="font-w600 mb-2 d-flex">
                      <span className="custom-label-2">
                        Current Designation :
                      </span>
                      <span className="font-w400">
                        {profile?.current_profile_role ?? ""}
                      </span>
                    </p>
                  )}
                  {profile?.current_salary && (
                    <p className="font-w600 mb-2 d-flex">
                      <span className="custom-label-2">Annual Salary : </span>
                      <span className="font-w400">
                        {profile?.current_salary ?? 0}
                      </span>
                    </p>
                  )}
                  {profile.current_previous_company && (
                    <p className="font-w600 mb-2 d-flex">
                      <span className="custom-label-2">Current Company : </span>
                      <span className="font-w400">
                        {profile.current_previous_company ?? ""}
                      </span>
                    </p>
                  )}
                  {profile.total_experience && (
                    <p className="font-w600 mb-2 d-flex">
                      <span className="custom-label-2">Experience : </span>
                      <span className="font-w400">
                        {profile.total_experience ?? 0}
                      </span>
                    </p>
                  )}
                  {profile?.city_name && (
                    <p className="font-w600 mb-2 d-flex">
                      <span className="custom-label-2">Location :</span>
                      <span className="font-w400">
                        {profile?.city_name ?? ""}
                      </span>
                    </p>
                  )}
                  {profile?.prefered_location && (
                    <p className="font-w600 mb-2 d-flex">
                      <span className="custom-label-2">
                        Preferred Location :
                      </span>
                      <span className="font-w400">
                        {profile?.prefered_location ?? "NA"}
                      </span>
                    </p>
                  )}
                  {profile?.highest_education && (
                    <p className="font-w600 mb-2 d-flex">
                      <span className="custom-label-2">Qualification: </span>
                      <span className="font-w400">
                        {profile?.highest_education ?? "NA"}
                      </span>
                    </p>
                  )}
                  {profile?.skills_name && (
                    <p className="font-w600 mb-2 d-flex">
                      <span className="custom-label-2">Skills: </span>
                      <span className="font-w400">
                        {profile?.skills_name ?? "NA"}
                      </span>
                    </p>
                  )}
                  {profile?.launguages && (
                    <p className="font-w600 mb-2 d-flex">
                      <span className="custom-label-2">Launguages :</span>
                      <span className="font-w400">
                        {profile?.launguages ?? "NA"}
                      </span>
                    </p>
                  )}
                  {profile?.email && (
                    <p className="font-w600 mb-2 d-flex">
                      <span className="custom-label-2">Email :</span>
                      <span className="font-w400">
                        {profile?.email ?? "NA"}
                      </span>
                    </p>
                  )}
                  {profile?.phone_number && (
                    <p className="font-w600 mb-2 d-flex">
                      <span className="custom-label-2">Phone : </span>
                      <span className="font-w400">
                        {profile?.phone_number ?? "NA"}
                      </span>
                    </p>
                  )}
                  {profile?.institution_name && (
                    <p className="font-w600 mb-2 d-flex">
                      <span className="custom-label-2">Industry :</span>
                      <span className="font-w400">
                        {profile?.institution_name ?? "NA"}
                      </span>
                    </p>
                  )}
                  {profile?.date_of_birth && (
                    <p className="font-w600 mb-2 d-flex">
                      <span className="custom-label-2">Date Of Birth : </span>
                      <span className="font-w400">
                        {profile?.date_of_birth ?? "NA"}
                      </span>
                    </p>
                  )}
                  {profile?.identify_name && (
                    <p className="font-w600 mb-2 d-flex">
                      <span className="custom-label-2">Gender : </span>
                      <span className="font-w400">
                        {profile?.identify_name ?? "NA"}
                      </span>
                    </p>
                  )}
                  {profile?.marital_status && (
                    <p className="font-w600 mb-2 d-flex">
                      <span className="custom-label-2">Marital Status : </span>
                      <span className="font-w400">
                        {profile?.marital_status ?? "NA"}
                      </span>
                    </p>
                  )}
                  {profile?.city_name && (
                    <p className="font-w600 mb-2 d-flex">
                      <span className="custom-label-2">
                        Permanent Address :
                      </span>
                      <span className="font-w400">
                        {profile?.city_name ?? "NA"}
                      </span>
                    </p>
                  )}
                  {profile?.zip_code && (
                    <p className="font-w600 mb-2 d-flex">
                      <span className="custom-label-2">Zip code: </span>
                      <span className="font-w400">
                        {profile?.zip_code ?? "NA"}
                      </span>
                    </p>
                  )}
                </div>
                <div className="col-xl-6 col-md-6">
                  <div className="card border">
                    <div className="card-body">
                      <h4 className="card-title text-center">Notes</h4>
                      <div
                        className="comments-container overflow-auto"
                        id="comments-container"
                      >
                        {comments?.length > 0 &&
                          comments.map((comm, i) => {
                            return (
                              <CommentComponent
                                //name={RecName}
                                //setFinalStatus={setFinalStatus}
                                comment={comm}
                                key={i}
                              />
                            );
                          })}
                      </div>
                      <form className="mt-4" onSubmit={handleAddNotes}>
                        <div className="form-group mb-2">
                          <div className="row">
                            <label
                              htmlFor=""
                              className="form-label col-md-3 pe-0"
                            >
                              Add your note
                            </label>

                            <div className="col-md-9">
                              <textarea
                                ref={noteRef}
                                className="form-control"
                                placeholder="Add your note"
                              ></textarea>
                            </div>
                          </div>
                        </div>

                        {/* <h6 className="mt-3">
                            Candidate Status: {finalStatus}
                          </h6> */}

                        <div className="form-group mb-2">
                          <div className="row">
                            <label
                              htmlFor=""
                              className="form-label col-md-3 pe-0"
                            >
                              Change Status :
                            </label>
                            <div className="col-md-9">
                              <select
                                ref={statusRef}
                                className="form-select"
                                aria-label="Default select example"
                              >
                                <option value="">
                                  Change Candidate Status
                                </option>
                                {candidateStatus.map((status, i) => {
                                  return (
                                    <option value={status?.value} key={i}>
                                      {status?.title}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="form-group text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm"
                          >
                            Add
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer d-flex flex-wrap justify-content-between">
              <div className="mb-md-2 mb-3">
                {/* {profile.current_previous_company && (
                  <span className="d-block mb-1">
                    <i className="fas fa-circle me-2"></i>Currently Working at
                    <strong> {profile.current_previous_company ?? ""}</strong>
                  </span>
                )}
                {profile.total_experience && (
                  <span>
                    <i className="fas fa-circle me-2"></i>
                    {profile.total_experience ?? 0} Of Working Experience in{" "}
                    <strong>{profile.current_previous_company ?? ""}</strong>
                  </span>
                )} */}
              </div>
              <div>
                {/* {profile?.resume_file && (
                  <Link
                    to={profile?.resume_file}
                    target="_blank"
                    className="btn btn-primary btn-sm me-2  mb-2"
                  >
                    <i className="fas fa-download me-2"></i>Download Resume
                  </Link>
                )} */}
                {/* <Link to={"#"} className="btn btn-warning btn-sm me-2 mb-2">
                  <i className="fas fa-share-alt me-2"></i>Share Profile
                </Link>
                <Link to={"#"} className="btn btn-info btn-sm me-2 mb-2">
                  <i className="fas fa-phone-alt me-2"></i>Contact
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <h4>Unlock Profile</h4>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Recruiter ID</th>
                      <th>Recruiter Name</th>
                      <th>Company Name</th>
                      <th>Email</th>
                      <th>Profile Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {profile?.unlocked_profiles?.length > 0 ? (
                      profile?.unlocked_profiles.map((profile, i) => {
                        return (
                          <tr>
                            <td>{profile?.recruiter_id}</td>
                            <td>{profile?.recruiter_name}</td>
                            <td>{profile?.company_name}</td>
                            <td>{profile?.email}</td>
                            <td>{profile?.profile_status}</td>
                            <td>{profile?.created_at}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <h4>Favorite Profile</h4>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Recruiter ID</th>
                      <th>Recruiter Name</th>
                      <th>Company Name</th>
                      <th>Email</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {profile?.favorite_profiles?.length > 0 ? (
                      profile?.favorite_profiles.map((profile, i) => {
                        return (
                          <tr>
                            <td>{profile?.recruiter_id}</td>
                            <td>{profile?.recruiter_name}</td>
                            <td>{profile?.company_name}</td>
                            <td>{profile?.email}</td>
                            <td>{profile?.created_at}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={5} className="text-center">
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <h4>Job Applications</h4>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Job ID</th>
                      <th>Unlocked</th>
                      <th>Profile Status</th>
                      <th>Job Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {profile?.job_applications?.length > 0 ? (
                      profile?.job_applications.map((profile, i) => {
                        return (
                          <tr>
                            <td>{profile?.job_id}</td>
                            <td>{profile?.unlock_flag}</td>
                            <td>{profile?.profile_status}</td>
                            <td>{profile?.job_status}</td>
                            <td>{profile?.created_at}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={5} className="text-center">
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserProfileAdmin;
