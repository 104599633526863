import React,{useState, useContext, useEffect} from "react";

import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
import profile from "../../../images/profile/pic1.jpg";
import avatar from "../../../images/avatar/1.jpg";
import { Dropdown, Modal } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";
import { getAllNotification } from "../../api/notification";
import moment from "moment";


const inputDate = [
  {title:'Salary Form', subtitle:'$'}, 
  {title:'Salary To', subtitle:'$'}, 
  {title:'Enter City', subtitle:'$'}, 
  {title:'Enter State', subtitle:'State'}, 
  {title:'Enter Counter', subtitle:'State'}, 
  {title:'Enter Education Level', subtitle:'Education Level'}, 
];

function Basic({title, subtitle}){
  return(    
    <div className="col-xl-6  col-md-6 mb-4">
       <label  className="form-label font-w600">{title}<span className="text-danger ms-1">*</span></label>
       <input type="text" className="form-control solid" placeholder={subtitle} aria-label="name" />
    </div>
    
  )
}
export function NotificationItemComponent({ item }) {
  const { is_read_by_admin, message, created_at = "" } = item;
  return (
    <li style={{ backgroundColor: is_read_by_admin ? "none" : "#F6EAEA" }}>
      <div className="timeline-panel p-2">
        <div className="media-body">
          <h5 className="mb-1">{message}</h5>
          <small className="d-block">
            {created_at ? moment(created_at).fromNow() : ""}
          </small>
        </div>
      </div>
    </li>
  );
}
const Header = ({ onNote }) => {
  const {background, changeBackground } = useContext(ThemeContext);
	const handleThemeMode = () => {
		if(background.value === 'dark'){
			changeBackground({ value: "light", label: "Light" });
		}else{
			changeBackground({ value: "dark", label: "Dark" });
		}
	}

  const [searchBut, setSearchBut] = useState(false);	
  let pdata = JSON.parse(localStorage.getItem('userDetails'));
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("_");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;

    const [jobModal,setJobModal] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
      dataFetch();
    }, [])
    const dataFetch = async () => {
      let params = {
        user_type: "0",
        user_id: '1',
        page: 1,
        limit: 6,
      };
      let data = await getAllNotification(params);
      setData(data)
    }
  return (
    <>
      <div className="header">
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left">
                <div
                  className="dashboard_bar"
                  style={{ textTransform: "capitalize" }}
                  >
                  {finalName.join(" ").length === 0
                    ? "Dashboard"
                    : finalName.join(" ") === "dashboard dark"
                    ? "Dashboard"
                    : finalName.join(" ")}
                </div>
                <div className="nav-item d-flex align-items-center">
                </div>
              </div>
              <ul className="navbar-nav header-right main-notification">	
                  <li className="nav-item dropdown notification_dropdown">
                      <Link to={"#"} 
                        className={`nav-link bell dz-theme-mode p-0 ${background.value === "dark" ? "active" : ""}`}
                        onClick={()=>handleThemeMode()}
                      >
                        <i id="icon-light" className="fas fa-sun" ></i>
                        <i id="icon-dark" className="fas fa-moon"></i>									
                      </Link>
                  </li>			
                {
                  pdata.roleid==1?
                  <>
                <Dropdown
                  as="li"
                  className="nav-item dropdown notification_dropdown "
                >
                  <Dropdown.Toggle className="nav-link i-false c-pointer" variant="" as="a"
                    data-toggle="dropdown" aria-expanded="false"
                  >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <g  data-name="Layer 2" transform="translate(-2 -2)">
                      <path id="Path_20" data-name="Path 20" d="M22.571,15.8V13.066a8.5,8.5,0,0,0-7.714-8.455V2.857a.857.857,0,0,0-1.714,0V4.611a8.5,8.5,0,0,0-7.714,8.455V15.8A4.293,4.293,0,0,0,2,20a2.574,2.574,0,0,0,2.571,2.571H9.8a4.286,4.286,0,0,0,8.4,0h5.23A2.574,2.574,0,0,0,26,20,4.293,4.293,0,0,0,22.571,15.8ZM7.143,13.066a6.789,6.789,0,0,1,6.78-6.78h.154a6.789,6.789,0,0,1,6.78,6.78v2.649H7.143ZM14,24.286a2.567,2.567,0,0,1-2.413-1.714h4.827A2.567,2.567,0,0,1,14,24.286Zm9.429-3.429H4.571A.858.858,0,0,1,3.714,20a2.574,2.574,0,0,1,2.571-2.571H21.714A2.574,2.574,0,0,1,24.286,20a.858.858,0,0,1-.857.857Z"/>
                    </g>
                  </svg>
                  <span className="badge light text-white bg-primary rounded-circle">{data?.total_unread_count ? data?.total_unread_count : 0}</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="right" className="mt-2 dropdown-menu dropdown-menu-end">
                    <PerfectScrollbar className="widget-media dlab-scroll p-3 ">
                      <ul className="timeline">
                        {
                          (data?.notifications || []).length > 0 ?<>
                           {(data?.notifications || []).map((noti, i) => (
                            <NotificationItemComponent item={noti} key={i} />
                          ))}
                          
                          </>
                          :<li className="text-center">No notification found</li>
                        }
                     
                      </ul>
                      <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
                        <div
                          className="ps__thumb-x"
                          tabIndex={0}
                          style={{ left: 0, width: 0 }}
                        />
                      </div>
                      <div className="ps__rail-y" style={{ top: 0, right: 0 }}>
                        <div
                          className="ps__thumb-y"
                          tabIndex={0}
                          style={{ top: 0, height: 0 }}
                        />
                      </div>
                    </PerfectScrollbar>
                    {
                      (data?.notifications || []).length > 0 ?
                    <Link className="all-notification" to="/notification_lists">
                      See all notifications <i className="ti-arrow-right" />
                    </Link>
                      :null
                    }
                  </Dropdown.Menu>
                </Dropdown>
                </>
                :<></>
               }
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <Modal className="modal fade bd-example-modal-lg" show={jobModal} onHide={setJobModal} centered size="lg">                
        <div className="modal-header">
          <h5 className="modal-title">Job Title</h5>
          <button type="button" className="btn-close" onClick={()=>setJobModal(false)}></button>
        </div>
        <div className="modal-body">
          <div className="row">
              {/* <div className="col-xl-6  col-md-6 mb-4">
                <label  className="form-label font-w600">Company Name<span className="text-danger ms-1">*</span></label>
                <input type="text" className="form-control solid" placeholder="Name" aria-label="name" />
              </div> */}
              <Basic title="Company Name" subtitle="Name" />
              <Basic title="Position" subtitle="Name" />             
              <div className="col-xl-6  col-md-6 mb-4">
                <label  className="form-label font-w600">Job Category<span className="text-danger ms-1">*</span></label>
                <select  className="nice-select default-select wide form-control solid">
                    <option selected>Choose...</option>
                    <option>QA Analyst</option>
                    <option>IT Manager</option>
                    <option>Systems Analyst</option>
                </select>
              </div>
              <div className="col-xl-6  col-md-6 mb-4">
                <label  className="form-label font-w600">Job Type<span className="text-danger ms-1">*</span></label>
                <select  className="nice-select default-select wide form-control solid">
                    <option selected>Choose...</option>
                    <option>Part-Time</option>
                    <option>Full-Time</option>
                    <option>Freelancer</option>
                </select>
              </div>              
              <Basic title="No. of Vancancy" subtitle="Name" />
              <div className="col-xl-6  col-md-6 mb-4">
                <label  className="form-label font-w600">Select Experience<span className="text-danger ms-1">*</span></label>
                  <select  className="nice-select default-select wide form-control solid">
                    <option selected>1 yr</option>
                    <option>2 Yr</option>
                    <option>3 Yr</option>
                    <option>4 Yr</option>
                  </select>
              </div>
              <div className="col-xl-6  col-md-6 mb-4">
                <label  className="form-label font-w600">Posted Date<span className="text-danger ms-1">*</span></label>
                <div className="input-group">
                    <div className="input-group-text"><i className="far fa-clock"></i></div>
                    <input type="date" name="datepicker" className="form-control" />
                </div>
              </div>
              <div className="col-xl-6  col-md-6 mb-4">
                <label  className="form-label font-w600">Last Date To Apply<span className="text-danger ms-1">*</span></label>
                <div className="input-group">
                    <div className="input-group-text"><i className="far fa-clock"></i></div>
                    <input type="date" name="datepicker" className="form-control" />
                </div>
              </div>
              <div className="col-xl-6  col-md-6 mb-4">
                <label  className="form-label font-w600">Close Date<span className="text-danger ms-1">*</span></label>
                <div className="input-group">
                    <div className="input-group-text"><i className="far fa-clock"></i></div>
                    <input type="date" name="datepicker" className="form-control" />
                </div>
              </div>
              <div className="col-xl-6  col-md-6 mb-4">
                <label  className="form-label font-w600">Select Gender:<span className="text-danger ms-1">*</span></label>
                  <select  className="nice-select default-select wide form-control solid">
                    <option selected>Choose...</option>
                    <option>Male</option>
                    <option>Female</option>
                  </select>
              </div>
              {inputDate.map((data, ind)=>(
                <div className="col-xl-6  col-md-6 mb-4" key={ind}>
                  <label  className="form-label font-w600">{data.title}<span className="text-danger ms-1">*</span></label>
                  <input type="text" className="form-control solid" placeholder={data.subtitle} aria-label="name" />
                </div>
              ))}             
              <div className="col-xl-12 mb-4">
                  <label  className="form-label font-w600">Description:<span className="text-danger ms-1">*</span></label>
                  <textarea className="form-control solid" rows="5" aria-label="With textarea"></textarea>
              </div>
            </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-danger light" 
            onClick={()=>setJobModal(false)}
          >Close</button>
          <button type="button" className="btn btn-primary">Save changes</button>
        </div>        
      </Modal>
    </>    
  );
};

export default Header;
