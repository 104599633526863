import axios from "axios";
import { fetchUserData } from "./usrinfo";
export const eventListAPI = async (params) => {
  try {
    let config = await fetchUserData();
    const response = await axios.post(
      `${process.env.REACT_APP_API_HOST}admin/event/listing`,
      params,
      config
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching menu items:", error);
  }
};

export const editEventAPI = async (id) => {
  try {
    let config = await fetchUserData();
    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}admin/event/${id}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching menu items:", error);
  }
};

export const addNewEvent = async (data) => {
  try {
    let config = await fetchUserData();
    await axios.post(
      `${process.env.REACT_APP_API_HOST}admin/event`,
      data,
      config
    );
  } catch (error) {
    console.error("Error on inserting logs", error);
  }
};

export const editEvent = async (data, id) => {
  try {
    let config = await fetchUserData();
    await axios.put(
      `${process.env.REACT_APP_API_HOST}admin/event/${id}`, // Ensure your URL is correct
      data,
      config
    );
  } catch (error) {
    console.error("Error on editing Event", error);
  }
};

// export const deleteEvent = async (id) => {
//     try {
//         let config = await fetchUserData();
//         await axios.delete(
//             `${process.env.REACT_APP_API_HOST}admin/event/${id}`,
//             config
//         );
//     } catch (error) {
//         console.error("Error on deleting Event", error);
//     }
// }

export const deleteEvent = async (data) => {
  try {
    let config = await fetchUserData();
    await axios.post(
      `${process.env.REACT_APP_API_HOST}admin/event/delete`,
      data,
      config
    );
  } catch (error) {
    console.error("Error on deleting Candidate", error);
  }
};
