import axios from "axios";
import { fetchUserData } from "./usrinfo";

export const notificationListAPI = async (data) => {
  try {
    let config = await fetchUserData();
    const response = await axios.post(
      `${process.env.REACT_APP_API_HOST}admin/notification-template/list`,
      data,
      config
    );
    return response;
  } catch (error) {
    console.error("Error fetching menu items:", error);
  }
};

export const editNotificationAPI = async (id) => {
  try {
    let config = await fetchUserData();
    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}admin/notification-template/${id}`,
      config
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching menu items:", error);
  }
};

export const addNewNotification = async (data) => {
  try {
    let config = await fetchUserData();
    let res = await axios.post(
      `${process.env.REACT_APP_API_HOST}admin/notification-template`,
      data,
      config
    );
    return res.data;
  } catch (error) {
    console.error("Error on inserting logs", error);
    return false;
  }
};

export const editNotification = async (data, id) => {
  try {
    let config = await fetchUserData();
    await axios.put(
      `${process.env.REACT_APP_API_HOST}admin/notification-template/${id}`, // Ensure your URL is correct
      data,
      config
    );
  } catch (error) {
    console.error("Error on editing Notification", error);
  }
};

export const deleteNotification = async (id) => {
  try {
    let config = await fetchUserData();
    await axios.delete(
      `${process.env.REACT_APP_API_HOST}admin/notification-template/${id}`,
      config
    );
  } catch (error) {
    console.error("Error on deleting Notification", error);
  }
};

export const scheduleNotification = async (data) => {
  try {
    let config = await fetchUserData();
    let res = await axios.post(
      `${process.env.REACT_APP_API_HOST}admin/schedule-notification`,
      data,
      config
    );
    return res.data || {};
  } catch (error) {
    console.error("Error on inserting logs", error);
    return false;
  }
};

export const setSchedulerData = async (data) => {
  try {
    let config = await fetchUserData();
    let res = await axios.post(
      `${process.env.REACT_APP_API_HOST}admin/notification-scheduler/scheduler/set`,
      data,
      config
    );
    return res.data || {};
  } catch (error) {
    console.error("Error on inserting logs", error);
    return false;
  }
};

export const getAllSchedulerData = async () => {
  try {
    let config = await fetchUserData();
    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}admin/notification-scheduler/scheduler/list`,
      config
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching menu items:", error);
  }
};

export const getAllNotification = async (data) => {
  try {
    let config = await fetchUserData();
    const response = await axios.post(
      `${process.env.REACT_APP_API_HOST}notification/get-notifications`,
      data,
      config
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching menu items:", error);
  }
};

export const allRead = async (data) => {
  try {
    let config = await fetchUserData();
    const response = await axios.post(
      `${process.env.REACT_APP_API_HOST}notification/update-all-read-by`,
      data,
      config
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching menu items:", error);
  }
};

export const deleteSchedulerNotification = async (id) => {
  try {
    let config = await fetchUserData();
    await axios.delete(
      `${process.env.REACT_APP_API_HOST}admin/notification-scheduler/scheduler/delete/${id}`,
      config
    );
  } catch (error) {
    console.error("Error on deleting Notification", error);
  }
};
