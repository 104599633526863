import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { userListAPI, editUserAPI, editUser, deleteUser } from "../../api/user";
import { roleListAPI } from "../../api/role";
import NewUser from "./NewUser";
import EditUser from "./EditUser";
import useAccess from "../../api/useAccess";
import { getReasonList } from "../../api/candidate";

const UserLists = () => {
  //Modal box
  const [addCard, setAddCard] = useState(false);
  const [users, setUsers] = useState([]);
  const [menuData, setMenuData] = useState([]);
  const [reason, setReason] = useState([]);
  const { fetchAccess } = useAccess();
  const permission = fetchAccess();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedJobs, setSelectedJobs] = useState([]);

  const fetchReason = useCallback(() => {
    getReasonList()
      .then((res) => {
        setReason(res);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetchReason();
    dataFetch();
    getRoleData();
  }, []);

  const getRoleData = async () => {
    let data = await roleListAPI();
    setMenuData(data);
  };

  const dataFetch = async () => {
    let data = await userListAPI();
    setUsers(data);
  };

  const reasonOptionList = useCallback(() => {
    var span = document.createElement("span");
    let list = "<option value=''>Select Reason</option>";
    if (reason) {
      for (let index = 0; index < reason.length; index++) {
        const res = reason[index];
        list +=
          `<option value="` +
          res.id +
          `" key="` +
          index +
          `"}>` +
          res.reason +
          `</option>`;
      }
    }
    span.innerHTML =
      `<select id="reason" class="form-control">` + list + `</select>`;
    return span;
  }, [reason]);

  // delete data
  const handleDeleteClick = async (contactId) => {
    var candidate;
    if (contactId) {
      candidate = [contactId];
    } else {
      candidate = selectedJobs;
    }
    if (candidate.length < 1) {
      swal("Opps", "Please select at least 1 user to delete.", "error");
      return false;
    }
    const span = reasonOptionList();
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this record?",
      icon: "warning",
      dangerMode: true,
      buttons: true,
      content: span,
    });

    if (willDelete) {
      let postData = {
        reason_id: document.getElementById("reason").value,
        ids: candidate.join(","),
      };
      //await deleteUser(contactId);
      await deleteUser(postData);
      setSelectAll(false);
      setSelectedJobs([]);
      dataFetch();
      swal("Deleted!", "Record has been deleted!", "success");
    }
  };

  //Edit start
  const [editModal, setEditModal] = useState(false);
  // Edit function editable page loop
  const [editContactId, setEditContactId] = useState(null);
  const fetchEditData = async (id) => {
    return await editUserAPI(id);
  };

  // Edit function button click to edit
  const handleEditClick = async (event, contact) => {
    event.preventDefault();
    setEditContactId(contact.id);
    setEditModal(true);
  };

  const handleCheckboxChange = (id) => {
    setSelectedJobs((prev) =>
      prev.includes(id) ? prev.filter((jobId) => jobId !== id) : [...prev, id]
    );
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    setSelectedJobs(isChecked ? users.map((job) => job.id) : []);
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600  me-auto">{""}</h4>
        <div>
          <button
            className="btn btn-danger btn-sm me-2"
            onClick={() => handleDeleteClick("")}
          >
            <i className="fa fa-trash"></i> Delete
          </button>
          <Link
            to={"#"}
            className="btn btn-warning me-3 btn-sm"
            onClick={() => setAddCard(true)}
          >
            <i className="fas fa-plus me-2"></i>Add New User
          </Link>
          <NewUser
            addCard={addCard}
            setAddCard={setAddCard}
            menuData={menuData}
            dataFetch={dataFetch}
          />
          <EditUser
            editModal={editModal}
            setEditModal={setEditModal}
            menuData={menuData}
            dataFetch={dataFetch}
            editid={editContactId}
            setEditContactId={setEditContactId}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <table className="table display mb-4 dataTablesCard Plan-table table-responsive-xl card-table dataTable no-footer">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </th>
                  <th>S.No</th>
                  <th>Display Name</th>
                  <th>Email Address</th>
                  <th>Role</th>
                  <th>Status</th>
                  {permission.v ? (
                    <>
                      <th>Actions</th>
                    </>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>
              <tbody>
                {users?.length > 0 ? (
                  users?.map((user, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedJobs.includes(user.id)}
                          onChange={() => handleCheckboxChange(user.id)}
                        />
                      </td>
                      <td>{index + 1}</td>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.role_name}</td>
                      <td>
                        <span
                          className={`badge badge-lg light badge-${
                            user.published == "1" ? "success" : "danger"
                          }`}
                        >
                          {user.published == "1" ? "Active" : "InActive"}
                        </span>
                      </td>
                      {permission.v === true ? (
                        <>
                          <td>
                            <div className="action-buttons d-flex justify-content-end">
                              {permission.e === true ? (
                                <>
                                  <Link
                                    to={"#"}
                                    className="btn btn-secondary light mr-2"
                                    onClick={(event) =>
                                      handleEditClick(event, user)
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24px"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      version="1.1"
                                      className="svg-main-icon"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth="1"
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <rect
                                          x="0"
                                          y="0"
                                          width="24"
                                          height="24"
                                        ></rect>
                                        <path
                                          d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                                          fill="#000000"
                                          fillRule="nonzero"
                                          transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
                                        ></path>
                                        <rect
                                          fill="#000000"
                                          opacity="0.3"
                                          x="5"
                                          y="20"
                                          width="15"
                                          height="2"
                                          rx="1"
                                        ></rect>
                                      </g>
                                    </svg>
                                  </Link>
                                </>
                              ) : (
                                <></>
                              )}
                              {permission.d === true ? (
                                <>
                                  <Link
                                    to={"#"}
                                    className="btn btn-danger light"
                                    onClick={() => handleDeleteClick(user.id)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24px"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      version="1.1"
                                      className="svg-main-icon"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth="1"
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <rect
                                          x="0"
                                          y="0"
                                          width="24"
                                          height="24"
                                        ></rect>
                                        <path
                                          d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                          fill="#000000"
                                          fillRule="nonzero"
                                        ></path>
                                        <path
                                          d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                          fill="#000000"
                                          opacity="0.3"
                                        ></path>
                                      </g>
                                    </svg>
                                  </Link>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </td>
                        </>
                      ) : (
                        <></>
                      )}
                    </tr>
                  ))
                ) : (
                  <>
                    <tr>
                      <td colSpan="100%" className="text-center">
                        {" "}
                        No Record Found.
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserLists;
