import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { subRecruiterListAPI } from "../../api/recruiter";
import _ from "lodash";

const ManageSubRecruiter = () => {
  const [addCard, setAddCard] = useState(false);
  const [events, setEvents] = useState([]);
  const [recruiterType] = useState([
    { id: 1, name: "Regular Recruiter" },
    { id: 2, name: "Internship Recruiter" },
  ]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "none",
  });
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  const rid = localStorage.getItem("rid");

  // Debounced Filter Handler
  const debouncedFilter = useMemo(() => {
    return _.debounce((value) => {
      setFilter(value);
      setPage(1); // Reset page when filtering
    }, 500);
  }, []);

  useEffect(() => {
    dataFetch();
  }, [sortConfig, page]);

  useEffect(() => {
    setPage(1); // Reset page on filter change
    dataFetch();
  }, [filter]);

  const dataFetch = async () => {
    console.log("calling fetch Data")
    const params = {
      page,
      limit: 10,
      sort_by: sortConfig.key,
      sort_direction: sortConfig.direction,
      keyword: filter,
      byAdmin: true,
      rid,
    };
    const data = await subRecruiterListAPI(params);
    if (page === 1) {
      setEvents(data);
    } else {
      setEvents((prevEvents) => [...prevEvents, ...data]);
    }
    setHasMore(data.length > 0); // Check if there are more events
  };

  // Sorting handler
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    } else if (sortConfig.key === key && sortConfig.direction === "desc") {
      direction = "asc";
    }
    setSortConfig({ key, direction });
    setPage(1); // Reset page on sort change
    setHasMore(true); // Reset pagination
  };

  // Edit start
  const [editModal, setEditModal] = useState(false);
  const [editContactId, setEditContactId] = useState(null);

  // Edit function button click to edit
  const handleEditClick = (id) => {
    setEditContactId(id);
    setEditModal(true);
  };

  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <span>▲</span>;
      } else if (sortConfig.direction === "desc") {
        return <span>▼</span>;
      }
    }
    return <span>↕</span>;
  };

  // Infinite Scroll Handler
  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      !hasMore
    )
      return;
    setPage((prevPage) => prevPage + 1);
  }, [hasMore]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const handleJobsClick = (e, recruiter_id) => {
    e.preventDefault();
    localStorage.setItem("subrecruiter_id", recruiter_id);
    localStorage.removeItem("recruiter_id");
    navigate("/job_list?type=subrecruiter");
  };

  const handleReportsClick = (e, recruiter_id) => {
    e.preventDefault();
    navigate(`/${recruiter_id}/recruiter-report`);
  };

  

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600 me-auto">{""}</h4>
      </div>
      <div className="row mb-4">
        <div className="col-md-6 ms-auto">
          <input
            type="text"
            placeholder="Filter by keyword"
            className="form-control me-3"
            onChange={(e) => debouncedFilter(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <table className="table display mb-4 dataTablesCard Plan-table table-responsive-xl card-table dataTable no-footer">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th onClick={() => handleSort("name")}>
                    Name {renderSortIcon("name")}
                  </th>
                  <th onClick={() => handleSort("email")}>
                    Email {renderSortIcon("email")}
                  </th>
                  <th onClick={() => handleSort("posted_jobs")}>
                    Posted Jobs {renderSortIcon("posted_jobs")}
                  </th>
                  <th onClick={() => handleSort("unlock_profiles")}>
                    Unlocked Profiles {renderSortIcon("unlock_profiles")}
                  </th>
                  <th onClick={() => handleSort("fav_profiles")}>
                    Fav Profiles {renderSortIcon("fav_profiles")}
                  </th>
                  
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {events?.length > 0 ? (
                  events.map((event, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{event?.name}</td>
                      <td>{event?.email}</td>
                      <td>{event?.posted_jobs}</td>
                      <td>{event.unlock_profiles}</td>
                      <td>{event.fav_profiles}</td>
                      <td>
                        <div className="d-flex justify-content-end">
                          <div title="View Jobs">
                            <Link
                              to="#"
                              className="btn btn-secondary light mr-2"
                              onClick={(e) => handleJobsClick(e, event.id)}
                            >
                              View Jobs
                            </Link>
                          </div>
                          <div title="View Reports">
                            <Link
                              to="#"
                              className="btn btn-secondary light mr-2"
                              onClick={(e) => handleReportsClick(e, event.id)}
                            >
                              View Reports
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="100%" className="text-center">
                      No Record Found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageSubRecruiter;
