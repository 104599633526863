import axios from "axios";
import { fetchUserData, fetchUserDataWithMutipart } from "./usrinfo";

export const jobListAPI = async (params) => {
  try {
    let config = await fetchUserData();
    const response = await axios.post(
      `${process.env.REACT_APP_API_HOST}admin/job/listing`,
      params,
      config
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching menu items:", error);
  }
};

export const getJobDetailsAPI = async (id) => {
  try {
    let config = await fetchUserData();
    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}admin/job/${id}`,
      config
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching menu items:", error);
  }
};

export const addNewJob = async (data) => {
  try {
    let config = await fetchUserData();
    let res = await axios.post(
      `${process.env.REACT_APP_API_HOST}admin/job`,
      data,
      config
    );
    return res.data;
  } catch (error) {
    console.error("Error on inserting logs", error);
    return false;
  }
};

export const updateJobAPI = async (id, data) => {
  try {
    let config = await fetchUserData();
    let res = await axios.put(
      `${process.env.REACT_APP_API_HOST}admin/job/${id}`, // Ensure your URL is correct
      data,
      config
    );
    return res.data;
  } catch (error) {
    console.error("Error on editing Job", error);
  }
};

// export const deleteJob = async (id) => {
//     try {
//         let config =  await fetchUserData();
//         await axios.delete(
//             `${process.env.REACT_APP_API_HOST}admin/job/${id}`,
//             config
//         );
//     } catch (error) {
//         console.error("Error on deleting Job", error);
//     }
// }

export const deleteJob = async (data) => {
  try {
    let config = await fetchUserData();
    await axios.post(
      `${process.env.REACT_APP_API_HOST}admin/job/delete`,
      data,
      config
    );
  } catch (error) {
    console.error("Error on deleting Candidate", error);
  }
};

export const jobCompanyList = async () => {
  try {
    let config = await fetchUserData();
    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}admin/job/companies`,
      config
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching company list:", error);
  }
};

export const verifyThisJob = async (params) => {
  try {
    let config = await fetchUserData();
    const response = await axios.post(
      `${process.env.REACT_APP_API_HOST}admin/job/verifyThisJob`,
      params,
      config
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching menu items:", error);
  }
};
