import React from "react";

function Tags() {
  return (
    <div className="card">
      <div className="card-body">
        <h4 className="text-center">coming soon...</h4>
      </div>
    </div>
  );
}

export default Tags;
