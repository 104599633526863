import React, { useContext } from "react";

/// React router dom
import { Routes, Route, Outlet } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./pages/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";

// added pride circle component
import RoleLists from "./components/RoleManagement/RoleLists";
import NewJob from "./components/RoleManagement/NewRole";
import PlanLists from "./components/Plans/PlanLists";
import UserLists from "./components/UserManagement/UserLists";
import CMSList from "./components/CmsManagement/CMSLists";
import EditList from "./components/CmsManagement/EditCMS";
import SkillLists from "./components/SkillsManagement/SkillLists";
import EventLists from "./components/EventManagement/EventLists";
import NotificationList from "./components/NotificationManagement/NotificationLists";
import AddNotification from "./components/NotificationManagement/NewNotification";
import EditNotification from "./components/NotificationManagement/EditNotification";
import AddCandidate from "./components/CandidateManagement/NewCandidate";
import CandidateLists from "./components/CandidateManagement/CandidateLists";
import EditCandidate from "./components/CandidateManagement/EditCandidate";
import CandidateView from "./components/CandidateManagement/CandidateView";
import RLists from "./components/RecruiterManagement/RLists";
import ManageSubRecruiter from "./components/RecruiterManagement/SubRecruiter";
import SubRecuiterReport from "./components/RecruiterManagement/Reports";
import NewR from "./components/RecruiterManagement/NewR";
import EditR from "./components/RecruiterManagement/EditR";
import JobList from "./components/JobManagement/JobLists";
import NewJobs from "./components/JobManagement/NewJob";
import EditJobs from "./components/JobManagement/EditJob";
import JobView from "./components/JobManagement/ViewJob";
import ActivityList from "./components/ActivityManagement/ActivityLists";
import SkillMLists from "./components/SkillsMatrixManagement/SkillMLists";
import Reporting from "./components/SkillsMatrixManagement/reporting";
import EditSetting from "./components/CmsManagement/Setting";
import NotificationSchedulerList from "./components/NotificationManagement/NotificationSchedulerList";

import DashboardDark from "./components/Dashboard/DashboardDark";
import Jobs from "./components/Dashboard/Jobs";
import Applications from "./components/Dashboard/Applications";
import MyProfile from "./components/Dashboard/MyProfile";
import Statistics from "./components/Dashboard/Statistics";
import Companies from "./components/Dashboard/Companies";
import Task from "./components/Dashboard/Task";

//Jobs
import JobLists from "./components/Jobs/JobLists";
import JobApplication from "./components/Jobs/JobApplication";
import ApplyJob from "./components/Jobs/ApplyJob";
//import NewJob from "./components/Jobs/NewJob";
import UserProfile from "./components/Jobs/UserProfile";
import UserProfileAdmin from "./components/Jobs/UserProfileAdmin";

//CMS
import Content from "./components/Cms/Content";
import Menu from "./components/Cms/Menu";
import EmailTemplate from "./components/Cms/EmailTemplate";
import Blog from "./components/Cms/Blog";
//CMS Linking Pages
import ContentAdd from "./components/Cms/ContentAdd";
import AddMail from "./components/Cms/AddMail";
import AddBlog from "./components/Cms/AddBlog";
import BlogCategory from "./components/Cms/BlogCategory";

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";

/// Product List
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import Customers from "./components/AppsMenu/Shop/Customers/Customers";

/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
//import Chartist from "./components/charts/chartist";
import RechartJs from "./components/charts/rechart";
import ApexChart from "./components/charts/apexcharts";

/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
//import Nestable from "./components/PluginsMenu/Nestable/Nestable";
//import MainNouiSlider from "./components/PluginsMenu/NouiSlider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";

//Redux
import Todo from "./pages/Todo";

/// Widget
import Widget from "./pages/Widget";

/// Table
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import DataTable from "./components/table/DataTable";
import BootstrapTable from "./components/table/BootstrapTable";

/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import CkEditor from "./components/Forms/CkEditor/CkEditor";
import Pickers from "./components/Forms/Pickers/Pickers";
import FormValidation from "./components/Forms/FormValidation/FormValidation";

/// Pages
// import Registration from "./pages/Registration";
// import Login from "./pages/Login";
// import ForgotPassword from "./pages/ForgotPassword";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import { ThemeContext } from "../context/ThemeContext";
import { components } from "react-select";
import NotificationsLists from "./components/NotificationManagement/NotificationsLists";
import ManageCandidates from "./components/App/ManageCandidates";
import FavouriteProfiles from "./components/App/FavouriteProfiles";
import UnlockProfile from "./components/App/UnlockProfile";
import JobFairVarification from "./components/App/JobFairVarification";
import AdminJobSort from "./components/App/AdminJobSort";
import PlanMetrix from "./components/App/PlanMetrix";
import PlanReport from "./components/App/PlanReport";
import Tags from "./components/App/Tags";

const Markup = () => {
  // const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  const allroutes = [
    /// allroutes
    { url: "", component: <Home /> },
    { url: "dashboard", component: <Home /> },
    { url: "dashboard-dark", component: <DashboardDark /> },
    { url: "search-jobs", component: <Jobs /> },
    { url: "applications", component: <Applications /> },
    { url: "my-profile", component: <MyProfile /> },
    { url: "statistics", component: <Statistics /> },
    { url: "companies", component: <Companies /> },
    { url: "task", component: <Task /> },

    //editors menu routes (!?/\/\)
    { url: "manage-candidate", component: <ManageCandidates /> },
    { url: "favourite-profiles", component: <FavouriteProfiles /> },
    { url: "unlock-profile", component: <UnlockProfile /> },
    { url: ":id/candidate-profile", component: <UserProfile /> },
    { url: ":id/view-candidate", component: <UserProfileAdmin /> },
    

    // new menus (!?/\/\)
    { url: "job-fair-verification", component: <JobFairVarification /> },
    { url: "jobs-sorting", component: <AdminJobSort /> },
    { url: "plan-metrix", component: <PlanMetrix /> },
    { url: ":plan_id/plan-report", component: <PlanReport /> },
    { url: "tags", component: <Tags /> },

    //Roles Added.. Pride circle
    { url: "role_list", component: <RoleLists /> },
    { url: "add-role", component: <NewJob /> },
    { url: "plan_list", component: <PlanLists /> },
    { url: "user_list", component: <UserLists /> },
    { url: "cms", component: <CMSList /> },
    { url: "editcms", component: <EditList /> },
    { url: "skill", component: <SkillLists /> },
    { url: "events", component: <EventLists /> },
    { url: "notification", component: <NotificationList /> },
    { url: "notification_lists", component: <NotificationsLists /> },
    { url: "add_notification", component: <AddNotification /> },
    { url: "edit_notification", component: <EditNotification /> },
    { url: "upload_candidate", component: <AddCandidate /> },
    { url: "c_lists", component: <CandidateLists /> },
    { url: "candidate_view", component: <CandidateView /> },
    { url: "edit_candidate", component: <EditCandidate /> },
    { url: "recruiter_list", component: <RLists /> },
    { url: "sub_recruiter_list", component: <ManageSubRecruiter /> },
    { url: ":id/recruiter-report", component: <SubRecuiterReport /> },
    { url: "job_list", component: <JobList /> },
    { url: "new_job", component: <NewJobs /> },
    { url: "edit_Job", component: <EditJobs /> },
    { url: "view_job", component: <JobView /> },
    { url: "logs", component: <ActivityList /> },
    { url: "skills_matrix", component: <SkillMLists /> },
    { url: "reporting", component: <Reporting /> },
    { url: "setting", component: <EditSetting /> },
    { url: "scheduler_listing", component: <NotificationSchedulerList /> },

    //Jobs
    { url: "job-list", component: <JobLists /> },
    { url: "job-application", component: <JobApplication /> },
    { url: "apply-job", component: <ApplyJob /> },
    { url: "new-job", component: <NewJob /> },
    //  { url: "user-profile", component: <UserProfile/> },

    //Cms
    { url: "content", component: <Content /> },
    { url: "menu", component: <Menu /> },
    { url: "email-template", component: <EmailTemplate /> },
    { url: "blog", component: <Blog /> },
    { url: "add-content", component: <ContentAdd /> },
    { url: "add-email", component: <AddMail /> },
    { url: "add-blog", component: <AddBlog /> },
    { url: "blog-category", component: <BlogCategory /> },

    /// Apps
    { url: "app-profile", component: <AppProfile /> },
    { url: "email-compose", component: <Compose /> },
    { url: "email-inbox", component: <Inbox /> },
    { url: "email-read", component: <Read /> },
    { url: "app-calender", component: <Calendar /> },
    { url: "post-details", component: <PostDetails /> },

    /// Chart
    { url: "chart-sparkline", component: <SparklineChart /> },
    { url: "chart-chartjs", component: <ChartJs /> },
    //{ url: "chart-chartist", component: Chartist },
    { url: "chart-apexchart", component: <ApexChart /> },
    { url: "chart-rechart", component: <RechartJs /> },

    /// Bootstrap
    { url: "ui-alert", component: <UiAlert /> },
    { url: "ui-badge", component: <UiBadge /> },
    { url: "ui-button", component: <UiButton /> },
    { url: "ui-modal", component: <UiModal /> },
    { url: "ui-button-group", component: <UiButtonGroup /> },
    { url: "ui-accordion", component: <UiAccordion /> },
    { url: "ui-list-group", component: <UiListGroup /> },
    { url: "ui-card", component: <UiCards /> },
    { url: "ui-carousel", component: <UiCarousel /> },
    { url: "ui-dropdown", component: <UiDropDown /> },
    { url: "ui-popover", component: <UiPopOver /> },
    { url: "ui-progressbar", component: <UiProgressBar /> },
    { url: "ui-tab", component: <UiTab /> },
    { url: "ui-pagination", component: <UiPagination /> },
    { url: "ui-typography", component: <UiTypography /> },
    { url: "ui-grid", component: <UiGrid /> },

    /// Plugin
    { url: "uc-select2", component: <Select2 /> },
    //{ url: "uc-nestable", component: Nestable },
    //{ url: "uc-noui-slider", component: MainNouiSlider },
    { url: "uc-sweetalert", component: <MainSweetAlert /> },
    { url: "uc-toastr", component: <Toastr /> },
    { url: "map-jqvmap", component: <JqvMap /> },
    { url: "uc-lightgallery", component: <Lightgallery /> },

    ///Redux
    { url: "todo", component: <Todo /> },
    /// Widget
    { url: "widget-basic", component: <Widget /> },

    /// Shop
    { url: "ecom-product-grid", component: <ProductGrid /> },
    { url: "ecom-product-list", component: <ProductList /> },
    { url: "ecom-product-detail", component: <ProductDetail /> },
    { url: "ecom-product-order", component: <ProductOrder /> },
    { url: "ecom-checkout", component: <Checkout /> },
    { url: "ecom-invoice", component: <Invoice /> },
    { url: "ecom-customers", component: <Customers /> },

    /// Form
    { url: "form-element", component: <Element /> },
    { url: "form-wizard", component: <Wizard /> },
    { url: "form-ckeditor", component: <CkEditor /> },
    { url: "form-pickers", component: <Pickers /> },
    { url: "form-validation", component: <FormValidation /> },

    /// table
    { url: "table-filtering", component: <FilteringTable /> },
    { url: "table-sorting", component: <SortingTable /> },
    { url: "table-datatable-basic", component: <DataTable /> },
    { url: "table-bootstrap-basic", component: <BootstrapTable /> },

  ];
  //let path = window.location.pathname;
  // path = path.split("/");
  // path = path[path.length - 1];

  
  

  // let pagePath = path.split("-").includes("page");
  return (
    <>
      <Routes>
        <Route path="page-lock-screen" element={<LockScreen />} />
        <Route path="page-error-400" element={<Error400 />} />
        <Route path="page-error-403" element={<Error403 />} />
        <Route path="page-error-404" element={<Error404 />} />
        <Route path="page-error-500" element={<Error500 />} />
        <Route path="page-error-503" element={<Error503 />} />
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <>
      <div
        id="main-wrapper"
        className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        <Nav />
        <div
          className="content-body"
          style={{ minHeight: window.screen.height - 45 }}
        >
          <div className="container-fluid">
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Markup;
