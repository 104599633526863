import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import axiosInstance from "../../../services/AxiosInstance";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import {
  getSelectedColumn,
  setSelectColumn,
} from "../../../services/ManageCandidateService";
import SearchInputComponent from "./Common/SearchInputComponent";
import {
  getIdentity,
  getLocations,
  getSkills,
} from "../../../services/JobsService";
import MultiSelect from "./Jobs/MultiSelect";

const allClm = [
  {
    title: "ID",
    clm: "id",
    sortable: true,
    sortField: "id",
  },
  {
    title: "Out at Work",
    clm: "out_at_work",
    sortable: true,
    sortField: "out_at_work",
  },
  {
    title: "Identify As",
    clm: "identify_name",
    sortable: true,
    sortField: "identify_name",
  },
  {
    title: "Current City",
    clm: "city_name",
    sortable: true,
    sortField: "city_name",
  },
  {
    title: "About Yourself",
    clm: "about_yourself",
    sortable: true,
    sortField: "about_yourself",
  },
  {
    title: "Qualification",
    clm: "highest_education",
    sortable: true,
    sortField: "highest_education",
  },
  {
    title: "Year of Passing",
    clm: "highest_education_passing_year",
    sortable: true,
    sortField: "highest_education_passing_year",
  },
  {
    title: "College",
    clm: "institution_name",
    sortable: true,
    sortField: "institution_name",
  },
  {
    title: "Apply for internship",
    clm: "interested_in_internships",
    sortable: true,
    sortField: "interested_in_internships",
  },
  {
    title: "Internship month(s)",
    clm: "internship_available_months",
    sortable: true,
    sortField: "internship_available_months",
  },
  {
    title: "Aggregate percentage",
    clm: "aggregate_percentage",
    sortable: true,
    sortField: "aggregate_percentage",
  },

  {
    title: "Experience",
    clm: "total_experience",
    sortable: true,
    sortField: "total_experience",
  },
  {
    title: "Current Employer",
    clm: "current_previous_company",
    sortable: true,
    sortField: "current_previous_company",
  },
  {
    title: "Current Job Role",
    clm: "current_profile_role",
    sortable: true,
    sortField: "current_profile_role",
  },
  {
    title: "Skills",
    clm: "skills",
    sortable: true,
    sortField: "skills",
  },
  {
    title: "CTC",
    clm: "current_salary",
    sortable: true,
    sortField: "current_salary",
  },
];

const tableClm = [
  {
    name: "View Action",
    button: "true",
    cell: (row) => (
      <Link target="_blank" to={`/${row?.id}/candidate-profile`}>
        <button className="btn btn-warning light mr-2">
          <i className="fa fa-eye"></i>
        </button>
      </Link>
    ),
  },
];

export default function ManageCandidates() {
  const optionCount = 60;
  const months = useMemo(() => {
    return [
      { value: 1, label: "January" },
      { value: 2, label: "February" },
      { value: 3, label: "March" },
      { value: 4, label: "April" },
      { value: 5, label: "May" },
      { value: 6, label: "June" },
      { value: 7, label: "July" },
      { value: 8, label: "August" },
      { value: 9, label: "September" },
      { value: 10, label: "October" },
      { value: 11, label: "November" },
      { value: 12, label: "December" },
    ];
  }, []);

  const percentage = useMemo(
    () => [
      { value: "Upto 50.00%", label: "Upto 50.00%" },
      { value: "50.01 to 60.00%", label: "50.01 to 60.00%" },
      { value: "60.01 to 70.00%", label: "60.01 to 70.00%" },
      { value: "70.01 to 80.00%", label: "70.01 to 80.00%" },
      { value: "80.01 to 90.00%", label: "80.01 to 90.00%" },
      { value: "90.01 to 100.00%", label: "90.01 to 100.00%" },
    ],
    []
  );

  const candidateStatus = useMemo(
    () => [
      { value: 1, title: "CV Shortlisted" },
      { value: 2, title: "Reject" },
      { value: 3, title: "On hold" },
      { value: 4, title: "Offered" },
      { value: 5, title: "DND" },
      { value: 6, title: "Others" },
      { value: 7, title: "In process" },
      { value: 8, title: "Interview Scheduled" },
      { value: 9, title: "Viewed(Action Pending)" },
    ],
    []
  );

  const selectOptions = useCallback(() => {
    let options = [];
    for (let index = 0; index <= optionCount; index++) {
      options.push(
        <option value={index} key={index}>
          {index}
        </option>
      );
    }
    return options;
  }, [optionCount]);

  const AdvanceSearchFields = useMemo(() => {
    return {
      adv_min_experience: "",
      adv_max_experience: "",
      adv_identify_as: "",
      adv_out_at_work: "",
      adv_qualification: "",
      adv_skills: "",
      adv_locations: "",
      adv_internship_months: "",
      adv_aggregate_percentage: "",
      adv_candidate_status: "",
    };
  }, []);

  //   //Modal box
  const [addModel, setAddModal] = useState(false);
  const [columnModel, setColumnModal] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRow, setSelectedRows] = useState([]);
  const [skills, setSkills] = useState([]);
  const [locations, setLocations] = useState([]);
  const [identities, setIdentities] = useState([]);

  const [selectedColumns, setSelectedColumn] = useState([]);
  const [columns, setColumn] = useState(tableClm);
  const [allColumns] = useState(allClm);

  const [toggleCleared, setToggleCleared] = useState(false);

  const [advFields, setAdvFields] = useState(AdvanceSearchFields);
  const [advanceParams, setAdvanceParams] = useState(AdvanceSearchFields);
  const [clearAdvanceSearch, setClearAdvanceSearch] = useState(false);

  const [reqParams, setReqParams] = useState({
    page: currentPage,
    limit: perPage,
    sort_by: "created_at",
    sort_direction: "DESC",
    keyword: "",
  });

  const [fetch, setFetch] = useState(true);

  const fetchUsers = useCallback(
    (params) => {
      setToggleCleared(!toggleCleared);
      setLoading(true);
      axiosInstance
        .post("/editor/candidates/listing", params)
        .then((res) => {
          setData(res?.data?.data);
          setTotalRows(res?.data?.totalRecords);
          setLoading(false);
          setFetch(false);
        })
        .catch((err) => {
          swal("Error", err?.message, "error");
          //console.log(err);
          setLoading(false);
          setFetch(false);
        });
    },
    [setFetch, toggleCleared]
  );

  const handleFetchColumns = useCallback(() => {
    getSelectedColumn()
      .then((resp) => {
        if (
          resp.data?.data[0] !== undefined &&
          resp.data?.data[0].search_keys !== undefined &&
          resp.data?.data[0].search_keys !== ""
        ) {
          let keys = resp.data?.data[0].search_keys;
          let keyArray = keys ? keys.split(",") : [];
          setSelectedColumn(keyArray);

          let newTableColumn = [];
          allColumns.forEach((clm, i) => {
            if (keyArray.includes(clm?.clm)) {
              newTableColumn.push({
                name: clm?.title,
                selector: (row) => row[clm?.clm],
                sortable: clm.sortable,
                sortField: clm.sortField,
              });
            }
            return false;
          });

          const tblClm = tableClm.concat(newTableColumn);
          setColumn(tblClm);
          //fetchUsers(currentPage);
          setFetch(true);
        }
      })
      .catch((err) => console.log(err));
  }, [setFetch]);

  useEffect(() => {
    handleFetchColumns();
  }, [handleFetchColumns]);

  useEffect(() => {
    if (fetch) {
      fetchUsers({ ...reqParams, ...advanceParams });
    }
  }, [fetch, reqParams, advanceParams]);

  const handleSearchText = useCallback(
   (text, isClear = false) => {
      if (text || isClear) {
        setReqParams((state) => {
          return { ...state, keyword: text };
        });
        setFetch(true);
      }
    },
    [setReqParams, setFetch]
  );

  const handleAddFavorite = useCallback(() => {
    const candidate = selectedRow.map((c, i) => {
      return c.id;
    });

    if (candidate?.length < 1) {
      swal("Opps", "Please select at least 1 candidate to favorite.", "error");
      return false;
    }

    axiosInstance
      .post("/editor/candidates/favorites/add", {
        candidate_ids: candidate,
      })
      .then((resp) => {
        if (resp?.data.code === "success") {
          swal("Great Job!", resp?.data.message, "success");
          //fetchUsers(currentPage);
          setToggleCleared(!toggleCleared);
          setSelectedRows([]);
          setFetch(true);
        } else if (resp?.data.code === "error") {
          swal("Opps", resp?.data.message, "error");
        }
      })
      .catch((error) => {
        //console.log(error?.response?.data?.message);
        swal("Opps", error?.response?.data?.message, "error");
      });
  }, [selectedRow, setFetch]);

  const handleUnlockProfile = useCallback(() => {
    const candidate = selectedRow.map((c, i) => {
      return c.id;
    });

    if (candidate?.length < 1) {
      swal("Opps", "Please select at least 1 candidate to unlock.", "error");
      return false;
    }

    axiosInstance
      .put("/editor/candidates/unlock-candidates", {
        candidate_ids: candidate,
      })
      .then((resp) => {
        if (resp?.data.code === "success") {
          //fetchUsers(currentPage);
          swal("Great Job!", resp?.data.message, "success");
          setToggleCleared(!toggleCleared);
          setSelectedRows([]);
          setFetch(true);
        } else if (resp?.data.code === "error") {
          swal("Opps", resp?.data.message, "error");
        }
      })
      .catch((err) => {
        swal("Opps", err?.response?.data?.message, "error");
        //console.log(err.message);
      });
  }, [selectedRow, setFetch]);

  const handlePageChange = useCallback(
    (page) => {
      setCurrentPage(page);
      setReqParams({
        ...reqParams,
        page: page,
      });
      setFetch(true);
    },
    [setReqParams, reqParams, setFetch]
  );

  const handlePerRowsChange = useCallback(
    async (newPerPage, page) => {
      setPerPage(newPerPage);
      setReqParams({
        ...reqParams,
        limit: newPerPage,
      });
      setFetch(true);
    },
    [setReqParams, reqParams, setFetch]
  );

  const handleSelectColumn = useCallback(() => {
    const checks = document.getElementsByClassName("column-check-box");
    let selectedChecks = [];
    for (let i = 0; i < checks.length; i++) {
      if (document.getElementsByClassName("column-check-box")[i].checked) {
        selectedChecks.push(
          document.getElementsByClassName("column-check-box")[i].value
        );
      }
    }

    setSelectedColumn(selectedChecks);

    let newTableColumn = [];
    allColumns.forEach((clm, i) => {
      if (selectedChecks.includes(clm?.clm)) {
        newTableColumn.push({
          name: clm?.title,
          selector: (row) => row[clm?.clm],
          sortable: clm.sortable,
          sortField: clm.sortField,
        });
      }
    });

    const tblClm = tableClm.concat(newTableColumn);
    setColumn(tblClm);

    setSelectColumn(selectedChecks)
      .then((resp) => {
        setTimeout(() => {
          setFetch(true);
        }, 1000);
      })
      .catch((error) => swal("Opps", error.message, "error"));
    setColumnModal(false);
  }, [allColumns, setColumn, setFetch]);

  const conditionalRowStyles = useMemo(() => {
    return [
      {
        when: (row) => true,
        style: (row) => {
          let bgClr = "",
            clr = "#000000de";

          if (row.is_favourite > 0) {
            bgClr = "#68e365";
            clr = "#fff";
          }

          if (row.is_unlock > 0) {
            bgClr = "#ffac98";
            clr = "#fff";
          }

          return {
            backgroundColor: bgClr,
            color: clr,
          };
        },
      },
    ];
  }, []);

  const checkAll = useCallback((isChecked) => {
    let list = document.getElementsByClassName("tbl-columns");
    if (isChecked) {
      for (let i = 0; i < list.length; i++) {
        document.getElementsByClassName("tbl-columns")[i].checked = true;
      }
    } else {
      for (let i = 0; i < list.length; i++) {
        document.getElementsByClassName("tbl-columns")[i].checked = false;
      }
    }
  }, []);

  const handleMultiSelect = useCallback(
    (values, inputname) => {
      if (values?.length > 0) {
        let ids = [];
        values.forEach((element, i) => {
          ids.push(element?.value);
        });
        setAdvFields({ ...advFields, [inputname]: ids.toString() });
      }
    },
    [advFields, setAdvFields]
  );

  const formatOptionList = useCallback((res, label, value, callback) => {
    if (res.length > 0) {
      let optionArray = [];
      res.forEach((obj, i) => {
        optionArray.push({ value: obj[value], label: obj[label] });
      });
      callback(optionArray);
    }
  }, []);

  const fetchPageData = useCallback(() => {
    getSkills()
      .then((res) => {
        formatOptionList(res?.data?.data, "skill_title", "id", setSkills);
      })
      .catch((err) => {
        console.log("Fetch Skills error " + err.message);
      });

    getLocations()
      .then((res) => {
        formatOptionList(res?.data?.data, "city_name", "id", setLocations);
      })
      .catch((err) => {
        console.log("Fetch Location error " + err.message);
      });

    getIdentity()
      .then((res) => {
        formatOptionList(res?.data?.data, "identify_name", "id", setIdentities);
      })
      .catch((err) => {
        console.log("Fetch Location error " + err.message);
      });
  }, [formatOptionList, setSkills]);

  useEffect(() => {
    fetchPageData();
  }, [fetchPageData]);

  const onSaveAdvanceSearch = useCallback(() => {
    setAdvanceParams(advFields);
    setClearAdvanceSearch(true);
    setAddModal(false);
    setFetch(true);
  }, [
    setAdvanceParams,
    advFields,
    setFetch,
    setClearAdvanceSearch,
    setAddModal,
  ]);

  const onClearAdvanceSearch = useCallback(() => {
    setClearAdvanceSearch(false);
    setAdvFields(AdvanceSearchFields);
    setAdvanceParams(AdvanceSearchFields);
    setFetch(true);
  }, [
    setClearAdvanceSearch,
    AdvanceSearchFields,
    setAdvanceParams,
    setAdvFields,
    setFetch,
  ]);

  const handleSort = useCallback(
    (column, sortDirection) => {
      if (column?.sortField !== undefined) {
        setReqParams({
          ...reqParams,
          sort_by: column.sortField,
          sort_direction: sortDirection,
        });
        setFetch(true);
      }
    },
    [reqParams, setReqParams, setFetch]
  );

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600  me-auto">{""}</h4>
        <div>
          {clearAdvanceSearch && (
            <button
              onClick={onClearAdvanceSearch}
              className="btn btn-danger me-3 btn-sm"
            >
              <i className="fas fa-times me-1"></i>Reset
            </button>
          )}
          <button
            onClick={() => setAddModal(true)}
            className="btn btn-warning me-3 btn-sm"
          >
            <i className="fas fa-search me-1"></i>Search
          </button>
          <button
            onClick={() => setColumnModal(true)}
            className="btn btn-secondary me-3 btn-sm"
          >
            Select Column
          </button>
          <button
            onClick={handleAddFavorite}
            className="btn btn-success me-3 btn-sm"
          >
            <i className="fas fa-user-check me-1"></i> Add to Favourite
          </button>
          <button
            onClick={handleUnlockProfile}
            className="btn btn-danger btn-unlock btn-sm"
          >
            <i className="fas fa-unlock me-1"></i>Unlock Selected
          </button>

          {/* <Link
            to={"#"}
            className="btn btn-primary me-3 btn-sm"
            onClick={() => setAddCard(true)}
          >
            <i className="fas fa-plus me-2"></i>Add New Job
          </Link>
          <Modal className="modal fade" show={addCard} onHide={setAddCard}>
            <div role="document">
              <div className="">
                <form>
                  <div className="modal-header">
                    <h4 className="modal-title fs-20">Add Contact</h4>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => setAddCard(false)}
                      data-dismiss="modal"
                    >
                      <span></span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <i
                      className="flaticon-cancel-12 close"
                      data-dismiss="modal"
                    ></i>
                    <div className="add-contact-box">
                      <div className="add-contact-content">
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Position
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              autocomplete="off"
                              name="position"
                              required="required"
                              onChange={handleAddFormChange}
                              placeholder="position"
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">Type</label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              autocomplete="off"
                              name="type"
                              required="required"
                              onChange={handleAddFormChange}
                              placeholder="type"
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Posted Date
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              autocomplete="off"
                              name="pdate"
                              required="required"
                              onChange={handleAddFormChange}
                              placeholder="pdate"
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Last Date
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              autocomplete="off"
                              name="ldate"
                              required="required"
                              onChange={handleAddFormChange}
                              placeholder="ldate"
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Close Date
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              autocomplete="off"
                              name="cdate"
                              required="required"
                              onChange={handleAddFormChange}
                              placeholder="cdate"
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">Status</label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              autocomplete="off"
                              name="status"
                              required="required"
                              onChange={handleAddFormChange}
                              placeholder="status"
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-success"
                      onClick={handleAddFormSubmit}
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      onClick={() => setAddCard(false)}
                      className="btn btn-danger"
                    >
                      {" "}
                      <i className="flaticon-delete-1"></i> Discard
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>
          <Modal className="modal fade" show={editModal} onHide={setEditModal}>
            <div role="document">
              <div>
                <form>
                  <div className="modal-header">
                    <h4 className="modal-title fs-20">Add Contact</h4>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => setEditModal(false)}
                      data-dismiss="modal"
                    >
                      <span></span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <i
                      className="flaticon-cancel-12 close"
                      data-dismiss="modal"
                    ></i>
                    <div className="add-contact-box">
                      <div className="add-contact-content">
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Position
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              autocomplete="off"
                              name="position"
                              required="required"
                              value={editFormData.position}
                              onChange={handleEditFormChange}
                              placeholder="position"
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">Type</label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              autocomplete="off"
                              name="type"
                              required="required"
                              value={editFormData.type}
                              onChange={handleEditFormChange}
                              placeholder="type"
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Posted Date
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              autocomplete="off"
                              name="pdate"
                              required="required"
                              value={editFormData.pdate}
                              onChange={handleEditFormChange}
                              placeholder="pdate"
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Last Date
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              autocomplete="off"
                              name="ldate"
                              required="required"
                              value={editFormData.ldate}
                              onChange={handleEditFormChange}
                              placeholder="ldate"
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Close Date
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              autocomplete="off"
                              name="cdate"
                              required="required"
                              value={editFormData.cdate}
                              onChange={handleEditFormChange}
                              placeholder="cdate"
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">Status</label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              autocomplete="off"
                              name="status"
                              required="required"
                              value={editFormData.status}
                              onChange={handleEditFormChange}
                              placeholder="status"
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleEditFormSubmit}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={() => setEditModal(false)}
                      className="btn btn-danger"
                    >
                      {" "}
                      <i className="flaticon-delete-1"></i> Discard
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>
          <Link to={"#"} className="btn btn-secondary btn-sm me-3">
            {" "}
            <i className="fas fa-envelope"></i>
          </Link>
          <Link to={"#"} className="btn btn-secondary btn-sm me-3">
            <i className="fas fa-phone-alt"></i>
          </Link>
          <Link to={"#"} className="btn btn-secondary btn-sm">
            <i className="fas fa-info"></i>
          </Link> */}
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <DataTable
              className="table  display dataTablesCard job-table table-responsive-xl card-table dataTable no-footer"
              columns={columns}
              data={data}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationRowsPerPageOptions={[10, 20, 30, 50, 100, 200, 500]}
              selectableRows
              persistTableHead
              subHeader
              conditionalRowStyles={conditionalRowStyles}
              subHeaderComponent={
                <SearchInputComponent setFilterText={handleSearchText} />
              }
              clearSelectedRows={toggleCleared}
              onSelectedRowsChange={({ selectedRows }) =>
                setSelectedRows(selectedRows)
              }
              onSort={handleSort}
              sortServer
              fixedHeader={true}
              fixedHeaderScrollHeight={'500px'}
            />
          </div>
        </div>
        {/* <div className="d-md-flex d-block align-items-center justify-content-between text-center  flex-wrap mt-md-0 mt-3">
          <div className="mb-md-0 mb-2">
            <h5 className="mb-0">Showing 1 to 7 of 7 entries</h5>
          </div>
          <nav>
            <ul className="pagination pagination-circle justify-content-center">
              <li className="page-item page-indicator">
                <Link to={"#"} className="page-link">
                  <i className="fa fa-angle-double-left" />
                </Link>
              </li>
              <li className="page-item active">
                <Link to={"#"} className="page-link">
                  1
                </Link>
              </li>
              <li className="page-item page-indicator">
                <Link to={"#"} className="page-link">
                  <i className="fa fa-angle-double-right" />
                </Link>
              </li>
            </ul>
          </nav>
        </div> */}
      </div>

      <Modal
        className="modal fade"
        size="lg"
        show={columnModel}
        backdrop="static"
        onHide={setColumnModal}
      >
        <div role="document">
          <div className="">
            <div className="modal-header">
              <h4 className="modal-title fs-20">Select columns to be shown.</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setColumnModal(false)}
                data-dismiss="modal"
              >
                <span></span>
              </button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-contact-box">
                <div className="add-contact-content" id="setColumn">
                  <div className="row">
                    {allColumns.map((column, i) => {
                      return (
                        <div className="col-md-4 form-group" key={i}>
                          <input
                            id={column?.clm}
                            className="form-check-input tbl-columns column-check-box"
                            type="checkbox"
                            value={column?.clm}
                            defaultChecked={selectedColumns.includes(
                              column?.clm
                            )}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={column?.clm}
                          >
                            {column.title}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  <div className="row">
                    <div className="col-md-4 form-group">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="select-all"
                        onChange={(e) => {
                          checkAll(e.target.checked);
                        }}
                      />
                      <label className="form-check-label" htmlFor="select-all">
                        Select All
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-success"
                onClick={handleSelectColumn}
              >
                Save
              </button>
              <button
                type="button"
                onClick={() => setColumnModal(false)}
                className="btn btn-danger"
              >
                <i className="flaticon-delete-1"></i> Close
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal fade"
        size="lg"
        show={addModel}
        backdrop="static"
        onHide={setAddModal}
      >
        <div role="document">
          <div className="modal-header">
            <h4 className="modal-title fs-20">Search</h4>
            <button
              type="button"
              className="btn-close"
              onClick={() => setAddModal(false)}
              data-dismiss="modal"
            >
              <span></span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="formGroupExampleInput">
                      Experience min
                    </label>
                    <div className="input-group mb-3 form-group">
                      <select
                        id="adv_min_experience"
                        className="form-select"
                        type="text"
                        name="adv_min_experience"
                        onChange={(e) =>
                          setAdvFields({
                            ...advFields,
                            adv_min_experience: e.target.value,
                          })
                        }
                      >
                        <option value="">Please Select</option>
                        {selectOptions()}
                      </select>
                      <span className="input-group-text" id="basic-addon1">
                        Years
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="formGroupExampleInput">
                      Experience max
                    </label>
                    <div className="input-group mb-3 form-group">
                      <select
                        id="adv_max_experience"
                        className="form-select"
                        type="text"
                        name="adv_max_experience"
                        onChange={(e) =>
                          setAdvFields({
                            ...advFields,
                            adv_max_experience: e.target.value,
                          })
                        }
                      >
                        <option value="">Please Select</option>
                        {selectOptions()}
                      </select>
                      <span className="input-group-text" id="basic-addon1">
                        Years
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="formGroupExampleInput">Identify as</label>
                    <div className="input-group mb-3 form-group">
                      <select
                        id="adv_identify_as"
                        className="form-select"
                        type="text"
                        name="adv_identify_as"
                        onChange={(e) =>
                          setAdvFields({
                            ...advFields,
                            adv_identify_as: e.target.value,
                          })
                        }
                      >
                        <option value="">Please Select</option>
                        {identities.map((identity, i) => (
                          <option value={identity.value} key={i}>
                            {identity.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="adv_out_at_work">OUT at work?</label>
                    <div className="input-group mb-3 form-group">
                      <select
                        id="adv_out_at_work"
                        className="form-select"
                        type="text"
                        name="adv_out_at_work"
                        onChange={(e) =>
                          setAdvFields({
                            ...advFields,
                            adv_out_at_work: e.target.value,
                          })
                        }
                      >
                        <option value="">Please Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="adv_qualification">Qualification</label>
                    <div className="input-group mb-3 form-group">
                      <input
                        type="text"
                        name="adv_qualification"
                        id="adv_qualification"
                        className="form-control form-control-sm"
                        placeholder="Qualification"
                        onChange={(e) =>
                          setAdvFields({
                            ...advFields,
                            adv_qualification: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="adv_skills">Skills</label>
                    <div className="mb-3 form-group">
                      <MultiSelect
                        multiple={true}
                        options={skills}
                        onValueSelect={handleMultiSelect}
                        name="adv_skills"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="adv_locations">Current City</label>
                    <div className="mb-3 form-group">
                      <MultiSelect
                        multiple={true}
                        options={locations}
                        onValueSelect={handleMultiSelect}
                        name="adv_locations"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="adv_internship_months">
                      Internship Month(s)
                    </label>
                    <div className="mb-3 form-group">
                      <MultiSelect
                        multiple={true}
                        options={months}
                        onValueSelect={handleMultiSelect}
                        name="adv_internship_months"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="adv_aggregate_percentage">
                      Aggregate Percentage
                    </label>
                    <div className="mb-3 form-group">
                      <MultiSelect
                        multiple={true}
                        options={percentage}
                        onValueSelect={handleMultiSelect}
                        name="adv_aggregate_percentage"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="adv_candidate_status">
                      Candidate Status
                    </label>
                    <div className="input-group mb-3 form-group">
                      <select
                        id="adv_candidate_status"
                        className="form-select"
                        type="text"
                        name="adv_candidate_status"
                        onChange={(e) =>
                          setAdvFields({
                            ...advFields,
                            adv_candidate_status: e.target.value,
                          })
                        }
                      >
                        <option value="">Please Select</option>
                        {candidateStatus.map((status, i) => (
                          <option value={status.value} key={i}>
                            {status.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="submit"
              className="btn btn-success"
              onClick={onSaveAdvanceSearch}
            >
              Search
            </button>
            <button
              type="button"
              onClick={() => setAddModal(false)}
              className="btn btn-danger"
            >
              <i className="flaticon-delete-1"></i> Close
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
