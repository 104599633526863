import axiosInstance from "./AxiosInstance";

export function getProfile(id) {
  return axiosInstance.get(`editor/candidates/${id}`);
}

export function getViewCandidate(id) {
  return axiosInstance.get(`admin/candidate/${id}`);
}

export function addNotes(data) {
  return axiosInstance.post(`editor/candidates/add-note`, data);
}

export function getNotes(candidate_id) {
  let data = { candidate_id };
  return axiosInstance.post(`editor/candidates/get-notes`, data);
}

export function getCandidateViewNotes(candidate_id) {
  //let data = { candidate_id };
  return axiosInstance.get(`admin/candidate/comments/${candidate_id}`);
}

// export function updateStatus(type, data) {
//   let cadidateType = type === "unlock" ? "unlock" : "applicant";
//   return axiosInstance.get(
//     `recruiter/candidates/${cadidateType}/profile-status`,
//     data
//   );
// }

export async function userProfile() {
  return axiosInstance.get("recruiter/user/recruiter-details");
}
