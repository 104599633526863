import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  getAllSchedulerData,
  deleteSchedulerNotification,
  notificationListAPI,
} from "../../api/notification";
import NotificationSchedule from "./NotificationSchedule";

const NotificationSchedulerList = () => {
  const [data, setData] = useState([]);
  const [ndata, setNData] = useState([]);
  const [notificationSchedulePopUp, setNotificationSchedulePopUp] =
    useState(false);
  const [notificationTypes] = useState([
    { ntype: 1, title: "whatsapp" },
    { ntype: 2, title: "Email" },
  ]);
  const [notificationFor] = useState([
    { ntype: 1, title: "Recruiter" },
    { ntype: 2, title: "Candidate" },
  ]);

  const notificationAction = {
    1: {
      3: "Account Expire Notification",
      4: "General Quota Expire",
      5: "Applicant Quota Expire",
      6: "Job Quota Expire",
      7: "Sub Recruiter Quota Expire",
      8: "Login Alert",
      9: "Job Alert",
      10: "Event Alert",
    },
    2: {
      8: "Login Alert",
    },
  };

  const navigate = useNavigate();

  useEffect(() => {
    dataFetch();
  }, []);

  const dataFetch = async () => {
    let data = await getAllSchedulerData();
    setData(data);
    const params = {
      page: 1,
      limit: 50,
      sort_by: "id",
      sort_direction: "ASC",
    };
    let ndata = await notificationListAPI(params);
    //console.log(ndata);

    //console.log("ndata", data);
    setNData(ndata?.data?.data);
  };

  // Edit function button click to edit
  const handleEditClick = async (event, id) => {
    event.preventDefault();
    localStorage.setItem("nid", id);
    navigate("/edit_notification");
  };

  // delete data
  const handleDeleteClick = async (contactId) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this record?",
      icon: "warning",
      dangerMode: true,
    });

    if (willDelete) {
      await deleteSchedulerNotification(contactId);
      dataFetch();
      swal("Deleted!", "Record has been deleted!", "success");
    }
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600  me-auto">{""}</h4>
        {
          <>
            <Link
              to={"/notification_lists"}
              className="btn btn-primary me-3 btn-sm"
            >
              Notification List
            </Link>
            <Link
              to={"#"}
              className="btn btn-primary me-3 btn-sm"
              onClick={() => setNotificationSchedulePopUp(true)}
            >
              Notification Schedule
            </Link>
            <Link
              to={"/add_notification"}
              className="btn btn-primary me-3 btn-sm"
            >
              <i className="fas fa-plus me-2"></i>Add New Template
            </Link>
          </>
        }
      </div>
      <NotificationSchedule
        isOpen={notificationSchedulePopUp}
        setIsOpen={setNotificationSchedulePopUp}
        data={ndata}
      />
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <table className="table display mb-4 dataTablesCard Plan-table table-responsive-xl card-table dataTable no-footer">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Notification For</th>
                  <th>Notification Type</th>
                  <th>Notification Action</th>
                  <th>Time(24)</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((cms, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{notificationFor[cms.notification_for - 1].title}</td>
                    <td>
                      {notificationTypes[cms.notification_type - 1].title}
                    </td>
                    <td>{notificationAction[1][cms.notification_actions]}</td>
                    <td>{cms.time}</td>
                    <td>
                      <div className="action-buttons d-flex justify-content-end">
                        {
                          <>
                            <Link
                              to={"#"}
                              className="btn btn-danger light"
                              onClick={() => handleDeleteClick(cms.id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                                className="svg-main-icon"
                              >
                                <g
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <path
                                    d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                    fill="#000000"
                                    fillRule="nonzero"
                                  ></path>
                                  <path
                                    d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                    fill="#000000"
                                    opacity="0.3"
                                  ></path>
                                </g>
                              </svg>
                            </Link>
                          </>
                        }
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default NotificationSchedulerList;
