import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCandidateDetailsAPI } from "../../api/candidate";

const CandidateView = () => {
  const [candidate, setCandidate] = useState({});
  const candidateId = localStorage.getItem("vid");

  useEffect(() => {
    const fetchCandidateDetails = async () => {
      const details = await getCandidateDetailsAPI(candidateId);
      setCandidate(details);
    };

    fetchCandidateDetails();
  }, [candidateId]);

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h3 className="mb-0 me-auto">Candidate View</h3>
        <div>
          <Link to="/c_lists" className="btn btn-primary me-3 btn-sm">
            <i className="fas fa-list me-2"></i>Candidate List
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-xxl-12">
          <div className="card">
            <div className="card-header border-0 pb-0">
              <h4 className="fs-20 mb-0">Overview</h4>
            </div>
            <div className="card-body pt-4">
              <div className="row">
                <div className="col-md-6 mb-3 d-flex">
                  <h5 className="mb-1 fs-14 custom-label">Name:</h5>
                  <span>{candidate.legal_name}</span>
                </div>
                <div className="col-md-6 mb-3 d-flex">
                  <h5 className="mb-1 fs-14 custom-label">Email:</h5>
                  <span>{candidate.email}</span>
                </div>
                <div className="col-md-6 mb-3 d-flex">
                  <h5 className="mb-1 fs-14 custom-label">Phone Number:</h5>
                  <span>{candidate.phone_number}</span>
                </div>
                <div className="col-md-6 mb-3 d-flex">
                  <h5 className="mb-1 fs-14 custom-label">WhatsApp Number:</h5>
                  <span>{candidate.whatsapp_number}</span>
                </div>
                <div className="col-md-6 mb-3 d-flex">
                  <h5 className="mb-1 fs-14 custom-label">Identify As:</h5>
                  <span>{candidate.identify_as}</span>
                </div>
                <div className="col-md-6 mb-3 d-flex">
                  <h5 className="mb-1 fs-14 custom-label">
                    Current Profile Role:
                  </h5>
                  <span>{candidate.current_profile_role}</span>
                </div>
                <div className="col-md-6 mb-3 d-flex">
                  <h5 className="mb-1 fs-14 custom-label">Key Skills:</h5>
                  <span>{candidate.skills_name}</span>
                </div>
                <div className="col-md-6 mb-3 d-flex">
                  <h5 className="mb-1 fs-14 custom-label">Current Salary:</h5>
                  <span>{candidate.current_salary?.toLocaleString()}</span>
                </div>

                <div className="col-md-6 mb-3 d-flex">
                  <h5 className="mb-1 fs-14 custom-label">Current Location:</h5>
                  <span>{candidate.current_location}</span>
                </div>
                <div className="col-md-6 mb-3 d-flex">
                  <h5 className="mb-1 fs-14 custom-label">
                    Preferred Location:
                  </h5>
                  <span>{candidate.prefered_location}</span>
                </div>
                <div className="col-md-6 mb-3 d-flex">
                  <h5 className="mb-1 fs-14 custom-label">Resume:</h5>
                  <span>
                    {candidate.resume_file ? (
                      <a href={candidate.resume_file_url} download>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-download"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 0a.5.5 0 0 1 .5.5V8h2.5a.5.5 0 0 1 .354.854l-3 3a.5.5 0 0 1-.708 0l-3-3A.5.5 0 0 1 5 8H7.5V.5A.5.5 0 0 1 8 0zM1.5 11a.5.5 0 0 1 .5-.5h12a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-12a.5.5 0 0 1-.5-.5v-4z" />
                        </svg>
                      </a>
                    ) : (
                      <></>
                    )}
                  </span>
                </div>
                {/*<div className="col-md-6 mb-3 d-flex">
                  <h5 className="mb-1 fs-14 custom-label">Candidate Status:</h5>
                  <span>{candidate.candidate_status}</span>
                </div>*/}

                <div className="col-md-6 mb-3 d-flex">
                  <h5 className="mb-1 fs-14 custom-label">LGBT Status:</h5>
                  <span>
                    {candidate.lgbt_status == 0
                      ? "Pending"
                      : candidate.lgbt_status == 1
                      ? "Confirmed"
                      : "Rejected"}
                  </span>
                </div>
                <div className="col-md-6 mb-3 d-flex">
                  <h5 className="mb-1 fs-14 custom-label">Published Status:</h5>
                  <span>
                    {candidate.published === "1" ? "Active" : "Inactive"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateView;
