import axios from "axios";
import { fetchUserData } from "./usrinfo";

export const userListAPI = async () => {
  try {
    const config = await fetchUserData();
    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}admin/user`,
      config
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching menu items:", error);
  }
};

export const editUserAPI = async (id) => {
  try {
    const config = await fetchUserData();
    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}admin/user/${id}`,
      config
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching menu items:", error);
  }
};

export const addNewUser = async (data) => {
  try {
    let config = await fetchUserData();
    await axios.post(
      `${process.env.REACT_APP_API_HOST}admin/user`,
      data,
      config
    );
  } catch (error) {
    console.error("Error on inserting logs", error);
  }
};

export const editUser = async (data, id) => {
  try {
    let config = await fetchUserData();
    await axios.put(
      `${process.env.REACT_APP_API_HOST}admin/user/${id}`, // Ensure your URL is correct
      data,
      config
    );
  } catch (error) {
    console.error("Error on editing user", error);
  }
};

export const deleteUser = async (data) => {
  try {
    let config = await fetchUserData();
    //   await axios.delete(
    //       `${process.env.REACT_APP_API_HOST}admin/user/${id}`,
    //       config
    //   );
    await axios.post(
      `${process.env.REACT_APP_API_HOST}admin/user/delete`,
      data,
      config
    );
  } catch (error) {
    console.error("Error on deleting User", error);
  }
};
