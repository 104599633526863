import React, { useEffect, useCallback, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import DataTable from "react-data-table-component";
import {
  notificationListAPI,
  deleteNotification,
} from "../../api/notification";
import useAccess from "../../api/useAccess";
import NotificationSchedule from "./NotificationSchedule";

const NotificationList = () => {
  const { fetchAccess } = useAccess();
  const permission = fetchAccess();
  //const [data, setData] = useState([]);
  const [notificationSchedulePopUp, setNotificationSchedulePopUp] =
    useState(false);
  const [notificationTypes] = useState([
    { ntype: 1, title: "whatsapp" },
    { ntype: 2, title: "Email" },
  ]);
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [reqParams, setReqParams] = useState({
    page: currentPage,
    limit: perPage,
    sort_by: "id",
    sort_direction: "ASC",
  });
  const [fetch, setFetch] = useState(true);

  const columns = useMemo(
    () => [
      {
        name: "S.No",
        selector: (row) => row.id,
        sortable: true,
        maxWidth: "50px",
        sortField: "id",
      },
      {
        name: "Notification Type",
        selector: (row) => notificationTypes[row.ntype - 1].title,
        wrap: true,
        maxWidth: "200px",
        sortable: true,
        sortField: "ntype",
      },
      {
        name: "Subject",
        selector: (row) => row.subject,
        wrap: true,
        sortable: true,
        sortField: "subject",
      },
      {
        name: "Actions",
        button: "true",
        cell: (row) => {
          return permission.v === true ? (
            <>
              {permission.e === true ? (
                <Link
                  to="#"
                  onClick={(event) => handleEditClick(event, row.id)}
                  className="btn btn-secondary btn-sm light me-2"
                >
                  <i className="fa fa-edit"></i>
                </Link>
              ) : (
                <></>
              )}
              {permission.d === true ? (
                <></>
              ) : (
                //  <Link
                //    to={"#"}
                //    className="btn btn-danger btn-sm light"
                //    onClick={() => handleDeleteClick(row.id)}
                //  >
                //    <i className="fa fa-trash"></i>
                //  </Link>
                <></>
              )}
            </>
          ) : (
            <></>
          );
        },
      },
    ],
    [permission]
  );

  const fetchUsers = useCallback(
    (params) => {
      setLoading(true);
      notificationListAPI(params)
        .then((res) => {
          setData(res?.data?.data);
          setTotalRows(res?.data?.totalRecords);
          setLoading(false);
          setFetch(false);
        })
        .catch((err) => {
          swal("Error", err?.message, "error");
          setLoading(false);
          setFetch(false);
        });
    },
    [setFetch]
  );

  useEffect(() => {
    if (fetch) {
      fetchUsers(reqParams);
    }
  }, [fetch, reqParams, fetchUsers]);

  //   useEffect(() => {
  //     dataFetch();
  //   }, []);

  //   const dataFetch = async () => {
  //     let data = await notificationListAPI();
  //     setData(data);
  //   };

  // Edit function button click to edit
  const handleEditClick = async (event, id) => {
    event.preventDefault();
    localStorage.setItem("nid", id);
    navigate("/edit_notification");
  };

  // delete data
  const handleDeleteClick = async (contactId) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this record?",
      icon: "warning",
      dangerMode: true,
      buttons: true,
    });

    if (willDelete) {
      await deleteNotification(contactId);
      //dataFetch();
      setFetch(true);
      swal("Deleted!", "Record has been deleted!", "success");
    }
  };

  //   const handleSearchText = useCallback(
  //     (text, isClear = false) => {
  //       if (text || isClear) {
  //         setReqParams((state) => {
  //           return { ...state, keyword: text };
  //         });
  //         setFetch(true);
  //       }
  //     },
  //     [setReqParams, setFetch]
  //   );

  const handlePageChange = useCallback(
    (page) => {
      setCurrentPage(page);
      setReqParams({
        ...reqParams,
        page: page,
      });
      setFetch(true);
    },
    [setReqParams, reqParams, setFetch]
  );

  const handlePerRowsChange = useCallback(
    async (newPerPage, page) => {
      setPerPage(newPerPage);
      setReqParams({
        ...reqParams,
        limit: newPerPage,
      });
      setFetch(true);
    },
    [setReqParams, reqParams, setFetch]
  );

  const handleExtraFilter = useCallback(
    (filter) => {
      setReqParams((state) => {
        return { ...state, ...filter };
      });
      setFetch(true);
    },
    [setFetch, setReqParams]
  );

  const handleSort = useCallback(
    (column, sortDirection) => {
      if (column?.sortField !== undefined) {
        setReqParams({
          ...reqParams,
          sort_by: column.sortField,
          sort_direction: sortDirection,
        });
        setFetch(true);
      }
    },
    [reqParams, setReqParams, setFetch]
  );

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600  me-auto">{""}</h4>
        {permission.a === true ? (
          <>
            {/* <Link
              to={"/notification_lists"}
              className="btn btn-primary me-3 btn-sm"
            >
              Notification List
            </Link> */}
            <Link
              to={"/scheduler_listing"}
              className="btn btn-primary me-3 btn-sm"
            >
              Set Scheduler Listing
            </Link>
            {/* <Link
              to={"/add_notification"}
              className="btn btn-primary me-3 btn-sm"
            >
              <i className="fas fa-plus me-2"></i>Add New Template
            </Link> */}
          </>
        ) : (
          <></>
        )}
      </div>
      <NotificationSchedule
        isOpen={notificationSchedulePopUp}
        setIsOpen={setNotificationSchedulePopUp}
        data={data}
      />
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            {/* <table className="table display mb-4 dataTablesCard Plan-table table-responsive-xl card-table dataTable no-footer">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Notification Type</th>
                  <th>Subject</th>
                  {permission.v ? (
                    <>
                      <th>Actions</th>
                    </>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>
              <tbody>
                {data.map((cms, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{notificationTypes[cms.ntype - 1].title}</td>
                    <td>{cms.subject}</td>
                    {permission.v === true ? (
                      <>
                        <td>
                          <div className="action-buttons d-flex justify-content-end">
                            {permission.e === true ? (
                              <>
                                <Link
                                  to="#"
                                  onClick={(event) =>
                                    handleEditClick(event, cms.id)
                                  }
                                  className="btn btn-secondary light mr-2"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                    className="svg-main-icon"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      ></rect>
                                      <path
                                        d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                                        fill="#000000"
                                        fillRule="nonzero"
                                        transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
                                      ></path>
                                      <rect
                                        fill="#000000"
                                        opacity="0.3"
                                        x="5"
                                        y="20"
                                        width="15"
                                        height="2"
                                        rx="1"
                                      ></rect>
                                    </g>
                                  </svg>
                                </Link>
                              </>
                            ) : (
                              <></>
                            )}

                            {permission.d === true ? (
                              <>
                                <Link
                                  to={"#"}
                                  className="btn btn-danger light"
                                  onClick={() => handleDeleteClick(cms.id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                    className="svg-main-icon"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      ></rect>
                                      <path
                                        d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                        fill="#000000"
                                        fillRule="nonzero"
                                      ></path>
                                      <path
                                        d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                        fill="#000000"
                                        opacity="0.3"
                                      ></path>
                                    </g>
                                  </svg>
                                </Link>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </td>
                      </>
                    ) : (
                      <></>
                    )}
                  </tr>
                ))}
              </tbody>
            </table> */}
            {permission.v === true && (
              <DataTable
                className="table display dataTablesCard job-table table-responsive-xl card-table dataTable no-footer"
                columns={columns}
                data={data}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                //   subHeader
                //   subHeaderComponent={
                //     <SearchInputComponent setFilterText={handleSearchText} />
                //   }
                persistTableHead
                onSort={handleSort}
                sortServer
                fixedHeader={true}
                fixedHeaderScrollHeight={"500px"}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default NotificationList;
